import { ReactNode } from 'react';

import { connect } from 'react-redux';

import { path } from 'ramda';

import * as operations from './operations';

const mapStateToProps = path(['campaigns', 'campaign', 'emailCreative']);

const emailConnect = connect(mapStateToProps, operations);

type ConnectedEmailProps = {
  children?: (props: any) => ReactNode;
};

export const ConnectedEmail = emailConnect(({ children, ...props }: ConnectedEmailProps) => {
  return children ? <>{children(props)}</> : null;
});

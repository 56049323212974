const PREFIX = 'map/masterplan/priorities';

export const GET_PRIORITIES = `${PREFIX}/GET_PRIORITIES`;
export const GET_PRIORITIES_SUCCESS = `${PREFIX}/GET_PRIORITIES_SUCCESS`;
export const GET_PRIORITIES_ERROR = `${PREFIX}/GET_PRIORITIES_ERROR`;

export const SAVE_PRIORITIES = `${PREFIX}/SAVE_PRIORITIES`;
export const SAVE_PRIORITIES_SUCCESS = `${PREFIX}/SAVE_PRIORITIES_SUCCESS`;
export const SAVE_PRIORITIES_ERROR = `${PREFIX}/SAVE_PRIORITIES_ERROR`;

export const UPDATE_PRIORITIES = `${PREFIX}/UPDATE_PRIORITIES`;

import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { compose } from 'ramda';
import styled from 'styled-components';

import { withAppConfig } from 'app/decorators';
import { saveVariantStatus as opSaveVariantStatus } from 'app/ducks/bannerManagement/binder/operations';
import { Modal as _Modal, Icon, Spinner } from 'app/midgarComponents';
import { BaseToggle } from 'app/midgarComponents/Toggle';
import { mixins, sc } from 'app/styles';
import { IVariant, IViewItem } from 'app/types/BannerManagement';
import { IAppConfig } from 'configs/apps/types';

type Props = {
  appConfig: IAppConfig;
  closeModal: (...args: Array<any>) => any;
  isOpen: boolean;
  readOnly?: boolean;
  // TODO: Connect to Redux to avoid using the return value
  saveVariantStatus?: (viewItem: IViewItem, variant: IVariant, status: boolean) => IViewItem;
  saveVariantStatusLoading?: Array<number>;
  viewItem?: IViewItem;
  withStatus?: boolean;
};

type State = {
  editingQuota: string;
  imageHeight: number;
  imageWidth: number;
  selectedVariant: IVariant | null | undefined;
  selectedViewItem: IViewItem | null | undefined;
};

class ImagePreviewModal extends PureComponent<Props, State> {
  state = {
    editingQuota: '',
    imageHeight: 0,
    imageWidth: 0,
    selectedVariant: null,
    selectedViewItem: null,
  };

  componentDidUpdate() {
    const { selectedViewItem } = this.state;
    const { isOpen, viewItem } = this.props;

    if (isOpen && viewItem && !selectedViewItem) {
      this.selectViewItem(viewItem);
      this.selectVariant(viewItem.variants[0] || {});
    }
  }

  closeModal = () => {
    const { closeModal } = this.props;

    this.setState({
      imageWidth: 0,
      imageHeight: 0,
      selectedViewItem: null,
      selectedVariant: null,
    });

    closeModal();
  };

  selectViewItem = (viewItem: Record<string, any>) => {
    this.setState({
      selectedViewItem: viewItem,
    });
  };

  selectVariant = (variant: Record<string, any>) => {
    const img = new Image();

    if (variant.image) {
      img.onload = () => {
        this.setState({
          imageWidth: img.width,
          imageHeight: img.height,
          selectedVariant: variant,
          editingQuota: '',
        });
      };

      img.src = variant.image;
    } else {
      this.setState({
        imageWidth: 0,
        imageHeight: 0,
        selectedVariant: variant,
        editingQuota: '',
      });
    }
  };

  onStatusToggle = async (status: boolean) => {
    const { selectedViewItem, selectedVariant } = this.state;
    const { saveVariantStatus } = this.props;

    const hydratedViewItem = await saveVariantStatus?.(selectedViewItem, selectedVariant, status);

    if (!hydratedViewItem || hydratedViewItem.error) {
      return;
    }

    this.setState({
      selectedViewItem: hydratedViewItem,
      selectedVariant: hydratedViewItem.variants?.find(x => x.id === selectedVariant?.id) || {},
    });
  };

  render() {
    const { imageHeight, imageWidth, selectedViewItem, selectedVariant } = this.state;
    const { isOpen, readOnly, saveVariantStatusLoading, withStatus } = this.props;

    const statusLoading = !!saveVariantStatusLoading?.find(id => id === selectedVariant?.id);

    if (!isOpen || !selectedViewItem || !selectedVariant) {
      return null;
    }

    return (
      <Modal dataQaValue={selectedViewItem?.id || 0} isOpen={isOpen} onClose={this.closeModal}>
        <Header>
          <h3>Preview</h3>

          <CloseIcon data-qa="closeModalButton" name="remove" onClick={this.closeModal} size={20} />
        </Header>

        <Content>
          <Preview>
            <PreviewImage src={selectedVariant.image} />
          </Preview>

          <SideBar>
            <GroupTitle>
              <span>Image</span>
            </GroupTitle>

            <SideBarField>
              <Label>Dimensions:</Label>
              <Value>{`${imageWidth} x ${imageHeight}`}</Value>
            </SideBarField>

            {selectedVariant.catalogBannerId && (
              <>
                <GroupTitle>
                  <span>Banner</span>
                </GroupTitle>

                <SideBarField>
                  <Label>Banner ID:</Label>
                  <Value>{selectedVariant.catalogBannerId}</Value>
                </SideBarField>

                <SideBarField>
                  <Label>Type:</Label>
                  <Value>{selectedVariant.landingPageType}</Value>
                </SideBarField>

                {selectedVariant.itemId ? (
                  <SideBarField>
                    <Label>Item ID:</Label>
                    <Value>{selectedVariant.itemId}</Value>
                  </SideBarField>
                ) : (
                  <SideBarField>
                    <Label>URL:</Label>
                    <Value>{selectedVariant.url}</Value>
                  </SideBarField>
                )}
              </>
            )}

            {withStatus && (
              <>
                <GroupTitle>
                  <span>Status</span>
                </GroupTitle>

                <StatusContainer>
                  <BaseToggle active={!!selectedVariant.status} disabled={readOnly} onChange={this.onStatusToggle} />

                  {statusLoading && <Spinner />}
                </StatusContainer>
              </>
            )}
          </SideBar>
        </Content>

        <Footer>
          {(selectedViewItem.variants || []).map(variant => (
            <FooterImageContainer
              key={`variant-image-${variant.id}`}
              active={selectedVariant.image === variant.image}
              onClick={() => this.selectVariant(variant)}
            >
              <FooterImage url={variant.image} />
            </FooterImageContainer>
          ))}
        </Footer>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  bannerManagement: {
    binder: { saveVariantStatusLoading },
  },
}) => ({
  saveVariantStatusLoading,
});

const mapDispatchToProps = {
  saveVariantStatus: opSaveVariantStatus,
};

export default compose(withAppConfig, connect(mapStateToProps, mapDispatchToProps))(ImagePreviewModal);

const Modal = styled(_Modal)`
  & > .ci-modal-inner {
    padding: ${sc.gutterLarge};
    width: 980px;
    height: 590px;
    background-color: #fff;
  }
`;

const Header = styled.div`
  padding-bottom: ${sc.gutterLarge};

  & > h3 {
    color: ${sc.headingColor};
    letter-spacing: 0.4px;
  }
`;

const Content = styled.div`
  height: 400px;
`;

const Preview = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 77%;
  height: 100%;
  margin-right: 2%;
  overflow: hidden;

  ${mixins.transparentGradient('#e6e6e6', 20)}
`;

const SideBar = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 21%;
  height: 100%;
  overflow-y: scroll;
`;

const SideBarField = styled.div`
  display: block;
  margin-bottom: ${sc.gutterSmaller};
`;

const GroupTitle = styled.div`
  position: relative;
  height: 1px;
  margin: ${sc.gutterLarger} 0 ${sc.gutterSmall};
  background-color: ${sc.sectionBorderColor};

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 0 ${sc.gutterSmall};
    background-color: #fff;

    color: ${sc.subHeadingColor};
    font-size: ${sc.fontSizeSmallest};
    font-weight: 600;
    text-transform: uppercase;
  }

  &:first-child {
    margin-top: ${sc.gutterSmaller};
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;

  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const Footer = styled.div`
  overflow-x: scroll;
  position: relative;
  width: 77%;
  padding: ${sc.gutterSmall} 0;
  padding-top: ${sc.gutter};
  white-space: nowrap;
`;

const FooterImageContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 130px;
  height: 80px;
  padding: 3px;
  margin-right: ${sc.gutterSmall};

  border: 2px solid ${props => (props.active ? sc.primary : 'transparent')};
  border-radius: 5px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

const FooterImage = styled.div`
  height: 100%;
  border-radius: 3px;

  background-image: url('${props => props.url}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const Label = styled.span`
  display: inline-block;
  vertical-align: top;
  margin-right: ${sc.gutterSmallest};

  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmaller};
  font-weight: 600;
`;

const Value = styled(Label)`
  font-weight: 400;
`;

const CloseIcon = styled(Icon)`
  padding: ${sc.gutterSmaller};
  position: absolute;
  top: ${sc.gutterSmaller};
  right: ${sc.gutterSmaller};
  color: ${sc.headingColor};

  &:hover {
    color: ${sc.primary};
    cursor: pointer;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  & > div {
    margin-right: ${sc.gutterSmall};
  }
`;

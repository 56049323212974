export const cashbackTypes = {
  regular: 'regular',
  journey: 'journey',
};

export const calculationTypes = {
  fixed: 'FIXED',
  lottery: 'LOTTERY',
  proportional: 'PROPORTIONAL',
  noreward: 'NOREWARD',
};

export type ICALC_TYPE_FIXED = 'FIXED';
export type ICALC_TYPE_LOTTERY = 'LOTTERY';
export type ICALC_TYPE_PROPORTIONAL = 'PROPORTIONAL';

export const calculationTypeLabelById = {
  [calculationTypes.fixed]: 'Fixed',
  [calculationTypes.lottery]: 'Lottery',
  [calculationTypes.proportional]: 'Proportional',
  [calculationTypes.noreward]: 'No Reward',
};

export const percentType = {
  global: 'GLOBAL',
  perPayment: 'PER_PAYMENT_METHOD',
};

export const percentTypeLabelById = {
  [percentType.global]: 'Global Percentage',
  [percentType.perPayment]: 'Per Payment Method',
};

export const fulfillmentTypes = {
  dayOfMonth: 'NEXT_MONTH_ON_DAY',
  instant: 'INSTANT',
  relative: 'RELATIVE',
};

export type FULFILLMENT_TYPE_DAY_OF_MONTH = 'NEXT_MONTH_ON_DAY';
export type FULFILLMENT_TYPE_INSTANT = 'INSTANT';
export type FULFILLMENT_TYPE_RELATIVE = 'RELATIVE';

export const fulfillmentTypeLabelById = {
  [fulfillmentTypes.dayOfMonth]: 'Absolute',
  [fulfillmentTypes.instant]: 'Immediately',
  [fulfillmentTypes.relative]: 'Relative',
};

export const promoEventFieldTypes = {
  event: 'EVENT',
  enrich: 'ENRICH',
};

export const stackCeilingByCalcType = {
  [calculationTypes.fixed]: 'REGULAR',
  [calculationTypes.proportional]: 'REGULAR',
  [calculationTypes.lottery]: 'LOTTERY',
  [calculationTypes.noreward]: 'NOREWARD',
};

export const currency = 'Yen';

export const reverseTypes = {
  specificStageReverse: {
    value: 'specificStageReverse',
    label: 'Specific Stage Cashback Reverse',
  },

  wholeStageReverse: {
    value: 'wholeStageReverse',
    label: 'Whole Stage Cashback Reverse',
  },
} as const;

export type ReverseTypesKeys = keyof typeof reverseTypes;

import { ReactNode } from 'react';

import { connect } from 'react-redux';

import * as actions from 'app/ducks/campaigns/campaign/TimelineNotificationCreative/actions';

const mapStateToProps = ({
  campaigns: {
    campaign: { TimelineNotificationCreative },
  },
}) => {
  return {
    ...TimelineNotificationCreative,
  };
};

const timelineNotificationConnect = connect(mapStateToProps, actions);

type ConnectedTimelineNotificationProps = {
  children?: (props: any) => ReactNode;
};

export const ConnectedTimelineNotification = timelineNotificationConnect(({ children, ...props }: ConnectedTimelineNotificationProps) => {
  return children ? <>{children(props)}</> : null;
});

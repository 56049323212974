import { fetchSlotGroupTypes as apiFetchSlotGroupTypes } from 'app/api/bannerManagementV2/viewItems';
import { DispatchFn, GetStateFn } from 'app/types/state';
import { PAYPAY_CONSUMER } from 'configs/apps/tenants/PAYPAY_CONSUMER';
import { getSelectedTenant } from 'configs/user';

import * as actions from './actions';

// next time a request to hide/unhide slot groups is made please seek status of PP-319007, this should ideally be handled on backend
export const hiddenSlotGroupTypes = ['override', 'ad', 'non-logged-in', 'event'];

export const fetchSlotGroupTypes = () => async (dispatch: DispatchFn, getState: GetStateFn) => {
  const {
    bannerManagement: {
      slotGroupTypes: { loading, slotGroupTypes: prevSlotGroupTypes },
    },
  } = getState();

  if (loading || (prevSlotGroupTypes || []).length > 0) {
    return;
  }

  dispatch(actions.getSlotGroupTypesStart());

  try {
    const slotGroupTypes = await apiFetchSlotGroupTypes();
    return dispatch(
      actions.getSlotGroupTypesSuccess(
        slotGroupTypes.filter(
          slotGroupType => !(getSelectedTenant() === PAYPAY_CONSUMER ? hiddenSlotGroupTypes : []).includes(slotGroupType.value),
        ),
      ),
    );
  } catch (err) {
    const errorStr = err.error && err.error.msg;
    return dispatch(actions.getSlotGroupTypesFailed(errorStr));
  }
};

import React, { PureComponent } from 'react';

import document from 'global/document';
import { ChromePicker } from 'react-color';
import styled, { css } from 'styled-components';

import { sc } from 'app/styles';
import { validateHex } from 'app/utilities/color';

type Props = {
  id?: string;
  name: string;
  label: string;
  value: string;
  onChange: (hex: string) => void;
  required?: boolean;
  error?: boolean;
  className?: string;
  disabled?: boolean;
};

type State = {
  active: boolean;
  focused: boolean;
  colorMenuOpen: boolean;
};

type Ref = { current: null | HTMLElement };

export default class ColorField extends PureComponent<Props, State> {
  wrapperRef: Ref;

  constructor(props: Props) {
    super(props);

    this.state = {
      active: !!props.value,
      focused: false,
      colorMenuOpen: false,
    };

    this.wrapperRef = React.createRef();

    document.addEventListener('keydown', this.handleKeydown);
    document.addEventListener('mousedown', this.handleOutsideClick);
    document.addEventListener('touchstart', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
    document.removeEventListener('mousedown', this.handleOutsideClick);
    document.removeEventListener('touchstart', this.handleOutsideClick);
  }

  componentDidUpdate() {
    const { active } = this.state;
    const { value } = this.props;

    if (!active && value) {
      this.setState({ active: true }); // eslint-disable-line
    }
  }

  handleKeydown = (ev: KeyboardEvent) => {
    const ESC = 27;

    if (ev.which === ESC) {
      this.setState({ colorMenuOpen: false });
    }
  };

  handleOutsideClick = (ev: InputEvent) => {
    const { colorMenuOpen } = this.state;

    if (colorMenuOpen) {
      const node = this.wrapperRef.current;

      if (node && !node.contains(ev.target)) {
        this.setState({ colorMenuOpen: false });
      }
    }
  };

  onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { disabled, onChange } = this.props;
    const hexValue = ev.target.value;

    if (!disabled && hexValue.length < 8) {
      onChange(hexValue);
    }
  };

  onFocus = () => {
    const { disabled } = this.props;

    if (!disabled) {
      this.setState({
        active: true,
        focused: true,
        colorMenuOpen: false,
      });
    }
  };

  onBlur = () => {
    const { value } = this.props;

    if (!value || !value.length) {
      return this.setState({
        active: false,
        focused: false,
      });
    }

    return this.setState({ focused: false });
  };

  onColorMenuToggle = () => {
    this.setState(state => ({
      colorMenuOpen: !state.colorMenuOpen,
    }));
  };

  onColorChange = (color: Record<string, any>) => {
    const { onChange, disabled } = this.props;

    if (!disabled) {
      onChange(color.hex);
    }
  };

  render() {
    const { active, focused, colorMenuOpen } = this.state;
    const { id, name, label, required, value, className, error, disabled, onChange, ...rest } = this.props;

    return (
      <Container ref={this.wrapperRef} className={className} $focused={focused} $error={!!error} disabled={disabled}>
        <InnerInput
          id={id}
          type="text"
          name={name}
          value={value}
          onChange={this.onChange}
          required={!!required}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          {...rest}
        />

        <InnerColor color={value} isValid={validateHex(value)} onClick={this.onColorMenuToggle} />

        <Label required={!!required} active={active}>
          {label}
        </Label>

        <PickerContainer top active={colorMenuOpen}>
          <ChromePicker disableAlpha color={value || '#fff'} onChangeComplete={this.onColorChange} />
        </PickerContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 55px;
  border: 1px solid ${props => (props.$focused ? sc.primary : props.$error ? sc.danger : sc.sectionBorderColor)};
  transition: border 150ms ease-in-out;

  opacity: ${props => (props.disabled ? 0.8 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const InnerInput = styled.input`
  width: calc(100% - 70px);
  height: 100%;
  border: none;
  padding: ${sc.gutter} ${sc.gutterSmall} ${sc.gutterSmallest};

  font-size: ${sc.fontSizeSmall};
  font-weight: bold;
`;

const InnerColor = styled.div`
  position: absolute;
  top: ${sc.gutterSmaller};
  right: ${sc.gutterSmaller};
  bottom: ${sc.gutterSmaller};
  width: 50px;

  border: 1px solid ${sc.sectionBorderColor};
  border-radius: 4px;
  background-color: ${props => (props.isValid ? props.color : '#fff')};
  cursor: pointer;

  ${props =>
    !props.isValid &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: -4px;
        right: -4px;
        bottom: 15px;
        height: 1px;
        background-color: ${sc.sectionBorderColor};
        transform: rotate(-33deg);
      }
    `}
`;

const Label = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: ${sc.gutterSmall};

  color: ${sc.subHeadingColor};
  font-size: ${sc.fontSizeSmall};
  transform: translate(0, -50%);
  transition: all 100ms ease-out;
  pointer-events: none;

  ${props =>
    props.required
      ? css`
          &:after {
            content: ' *';
          }
        `
      : ''}

  ${props =>
    props.active
      ? css`
          top: 30%;
          font-size: ${sc.fontSizeSmaller};
        `
      : ''}
`;

const PickerContainer = styled.div`
  display: ${props => (props.active ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: 100%;
  transform: translateY(5%);

  ${props =>
    props.top &&
    css`
      top: 0;
      transform: translateY(-105%);
    `}

  z-index: 120;
`;

import { StyledLabel } from './styledComponents';
import { Props } from './types';

export const Label = ({ children, ...rest }: Props) =>
  children && (
    <StyledLabel {...rest}>
      <span className="label-text">{children[0]}</span>
      {children[1]}
    </StyledLabel>
  );

import { IAppConfig, IChannelSetup } from 'configs/apps/types';
import { CampaignTargetTypes } from 'configs/apps/typescript/types';
import { channelNameEmail, channelNamePush2, channelNameTimelineNotification } from 'configs/channels/constants';

export const getChannels = (appConfig: IAppConfig): Array<IChannelSetup['name'] | IChannelSetup> =>
  appConfig ? appConfig.channels || [] : [];

export const getChannelNames = (appConfig: IAppConfig): Array<string> => {
  const channels = getChannels(appConfig).map(c => (typeof c === 'string' ? c : c.name));
  if (appConfig?.campaigns.selectedCampaignTarget === CampaignTargetTypes.MERCHANT) {
    return channels.filter(channel => [channelNameEmail, channelNamePush2].includes(channel));
  }

  if (appConfig?.isSecureRoom) {
    return channels.filter(channel => channel === channelNameTimelineNotification);
  }

  return channels;
};

/**
 * Get the `IChannelSetup` for the given channel.
 *
 * If the channel is active, and there is no custom setup for this channel, returns an object with only the `name` property.
 *
 * If the channel is not active, return `undefined`.
 *
 * @param {IAppConfig} appConfig
 * @param {string} channelName
 * @returns ?IChannelSetup
 */
export const findChannelSetup = (appConfig: IAppConfig, channelName?: string): Partial<IChannelSetup> | undefined => {
  const channel: IChannelSetup['name'] | IChannelSetup | undefined = getChannels(appConfig).find(config => {
    if (typeof channelName !== 'string') {
      return undefined;
    }

    return typeof config === 'string'
      ? channelName.toUpperCase() === config.toUpperCase()
      : channelName.toUpperCase() === config.name.toUpperCase();
  });

  return channel && typeof channel === 'string' ? { name: channel } : channel;
};

export const getDefaultCampaignTarget = (isSecureRoom: boolean) =>
  isSecureRoom ? CampaignTargetTypes.SECURE_ROOM : CampaignTargetTypes.CONSUMER;

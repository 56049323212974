import React, { useState, useEffect } from 'react';

import { ExpandableTableRow } from './ExpandableTableRow';

interface ExpandableTableRowProps {
  row: Record<string, any>;
  onRowMount?: (row: any) => void;
}

const ExpandableTableRowWithState: React.FC<ExpandableTableRowProps> = ({ row, onRowMount, ...props }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (onRowMount) {
      onRowMount(row);
    }
  });

  const handleExpandClick = () => {
    setExpanded(prev => !prev);
  };

  return <ExpandableTableRow expanded={expanded} onExpandClick={handleExpandClick} row={row} {...props} />;
};

export default ExpandableTableRowWithState;

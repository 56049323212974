import { combineReducers } from 'redux';

import campaigns, { stackableGroupCampaignsActions, stackableGroupCampaignsOperations } from './campaigns';
import events, { stackableGroupEventsActions, stackableGroupEventsOperations } from './events';

export { stackableGroupCampaignsActions, stackableGroupCampaignsOperations, stackableGroupEventsActions, stackableGroupEventsOperations };

export default combineReducers({
  campaigns,
  events,
});

import { equals } from 'ramda';
import { compose, lifecycle, withState } from 'recompose';

const addState = withState('prevApiQueryParams', 'setPrevApiQueryParams', {});

const doFetch = (fetchRecordsFnName: string) =>
  lifecycle<any, {}>({
    componentDidMount() {
      const { apiQueryParams, paging } = this.props;
      if (!paging) {
        const fnFetch = this.props[fetchRecordsFnName];
        if (fnFetch) {
          fnFetch(apiQueryParams);
        }
      }
    },

    componentDidUpdate() {
      const { apiQueryParams, prevApiQueryParams, setPrevApiQueryParams } = this.props;
      if (!equals(apiQueryParams, prevApiQueryParams)) {
        const fnFetch = this.props[fetchRecordsFnName];
        if (fnFetch) {
          fnFetch(apiQueryParams);
          setPrevApiQueryParams(apiQueryParams);
        }
      }
    },
  });

/**
 * Fetch the records whenever the `apiQueryParams` have changed.
 *
 * @param {string} fetchRecordFnName The name of the property which is the function that fetches the records.
 */
export default (fetchRecordsFnName: string) => compose(addState, doFetch(fetchRecordsFnName));

import React, { PureComponent } from 'react';

import { Modal } from '@material-ui/core';
import styled from 'styled-components';

import { sc } from 'app/styles';

import Button from '../Button';

export default class ConfirmModal extends PureComponent<{
  isOpen: boolean;
  title?: string;
  cancelText?: string;
  confirmText?: string;
  onClose: (...args: Array<any>) => any;
  onConfirm: (...args: Array<any>) => any;
  children?: React.ReactNode;
  disabled?: boolean;
}> {
  render() {
    const { isOpen, title, confirmText, cancelText, onClose, onConfirm, disabled, children } = this.props;

    return (
      <Modal open={isOpen} onClose={onClose} data-qa="confirm-modal">
        <Container>
          <Heading>{title}</Heading>
          <Description>{children || 'Are you sure you wish to continue?'}</Description>

          <ActionsContainer>
            <Button type="secondary" onClick={onClose} data-qa="cancel">
              {cancelText || 'Cancel'}
            </Button>

            <Button type="primary" onClick={onConfirm} disabled={disabled} data-qa="confirm">
              {confirmText || 'Confirm'}
            </Button>
          </ActionsContainer>
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
  width: 430px;
  background-color: #fff;
  padding: 30px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  color: ${sc.primary};
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const ActionsContainer = styled.div`
  width: 100%;
  text-align: right;

  & > button {
    margin-left: 10px;
  }
`;

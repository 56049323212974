type Channel = {
  name: string;
  triggers: Array<{ value: string }>;
};

export enum CampaignTargetTypes {
  CONSUMER = 'CONSUMER',
  MERCHANT = 'MERCHANT',
  SECURE_ROOM = 'SECURE_ROOM',
}

export type AppConfig = {
  analyticsUrl?: string;
  audiences?: {
    boost: boolean;
    csvColumnNames: Array<string>;
    versionChecked?: boolean;
  };
  branding?: string;
  campaigns?: {
    metrics: boolean;
    useStopState: boolean;
    enableDraftCampaignClone: boolean;
  };
  channels: Array<Channel>;
  enableCampaignTypes?: boolean;
  enableGoals: boolean;
  enableSchemaRegistry?: boolean;
  enableFeatureFilters?: boolean;
  enableMaxAudienceLimit?: boolean;
  isJourneyEnabled?: boolean;
  envTag?: string;
  supportedAudienceTypes?: Array<string>;
  supportedRoutes: Array<string>;
  supportedPermissions: Array<string>;
  timeZone?: string; // TODO: Check usage
  isSecureRoom: boolean;
  campaignTargetTypes: CampaignTargetTypes[];
};

import * as types from './types';

const initialState = {
  loading: true,
  promoEvents: null,
  promoEvent: [],
  customerEvent: null,
  loadingCustomerEvent: true,
};

const eventSchema = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_EVENTS:
      return {
        ...state,
        promoEvents: payload,
        loading: false,
      };

    case types.GET_CUSTOMER_EVENT:
      return {
        ...state,
        customerEvent: payload,
        loadingCustomerEvent: false,
      };

    case types.ADD_EVENT_SCHEMA:
      //@ts-expect-error expected error, needs to be reworked
      return [payload, ...state]; // TODO need re-work
    case types.SET_EVENT_SCHEMA:
      return payload;
    case types.INIT_CUSTOMER_EVENT:
      return {
        ...state,
        loadingCustomerEvent: true,
      };

    default:
      return state;
  }
};

export default eventSchema;

import { stringify } from 'query-string';

import { IMasterplanApiParams, IPrioritiesApiParams } from './types';

export default {
  priorities: (params: IPrioritiesApiParams) =>
    `/management/priorities/${params.date}/${params.medium}/${params.trigger}?${stringify({
      preview_plan: params.previewPlan,
      useCaseType: params.useCaseType,
    })}`,
  executions: (params: IMasterplanApiParams) =>
    `/management/v2/executions/${params.date}/status?channel=${params.medium}&useCaseType=${params.useCaseType}`,
};

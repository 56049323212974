import { triggerTypes, dedupeStrategies } from 'app/utilities/constants';
import { IDedupeType } from 'app/utilities/types';
import { emailMediumId, push2MediumId } from 'configs/channels/mediumIds';
const supportedMediumIds = [emailMediumId, push2MediumId];

// TODO: The medium names should be imported from the channel config, but cyclical imports need to be resolved
const mediums = {
  email: 'Email',
  push2: 'Push2',
};

const triggerNames = {
  scheduled: `${triggerTypes.oneTime},${triggerTypes.recurring}`,
  location: triggerTypes.location,
  cartAbandon: triggerTypes.cartAbandon,
};

const triggerOptionScheduled = { id: triggerNames.scheduled, label: 'Scheduled' };

const defaultTriggerOption = triggerOptionScheduled;

const triggerOptionsByMedium = {
  [mediums.email]: [triggerOptionScheduled],
  // Coordinator only supports ONE-TIME and RECURRING. Disable the other types until they are supported
  [mediums.push2]: [triggerOptionScheduled],
};

const states = {
  failed: 'FAILED',
  interrupted: 'INTERRUPTED',
  pending: 'PENDING',
  queued: 'QUEUED',
  running: 'RUNNING',
  success: 'SUCCEEDED',
};

const finalStates = {
  failed: 'failed',
  processing: 'processing',
  success: 'success',
  unknown: 'unknown',
  waiting: 'waiting',
};

const MANUAL = 'MANUAL';
const SYSTEM = 'SYSTEM';
type IPriorityType = 'MANUAL' | 'SYSTEM';

const CSV_HEADERS = [
  { label: 'ID', key: 'id' },
  { label: 'Priority', key: 'priority' },
  { label: 'Name', key: 'name' },
  { label: 'Type', key: 'type' },
  { label: 'Schedule Type', key: 'isRecurring' },
  { label: 'Push Type', key: 'pushType' },
  { label: 'Push Site', key: 'pushSite' },
  { label: 'Dedupe', key: 'dedupeLabel' },
  { label: 'Audience', key: 'countPerExecutionNumber' },
  { label: 'Acquired', key: 'acquiredNumber' },
  { label: 'Acquired Percentage', key: 'acquiredPercent' },
  { label: 'Acquired', key: 'deliveredNumber' },
  { label: 'Delivered Percentage', key: 'deliveredPercent' },
];

const dedupeLabels: Record<IDedupeType, string> = {
  [dedupeStrategies.softLock]: 'Soft',
  [dedupeStrategies.hardLock]: 'Hard',
  [dedupeStrategies.none]: 'None',
};

export {
  defaultTriggerOption,
  finalStates,
  states,
  supportedMediumIds,
  triggerNames,
  triggerOptionsByMedium,
  MANUAL,
  SYSTEM,
  CSV_HEADERS,
  dedupeLabels,
};

export type { IPriorityType };

import { IPrioritiesResult } from 'app/api/masterplan/types';
import { ICampaignPriority } from 'app/types/ICampaignPriority';

import * as types from './types';

export const getPriorities = () => ({ type: types.GET_PRIORITIES });
export const getPrioritiesSuccess = (payload: IPrioritiesResult) => ({ type: types.GET_PRIORITIES_SUCCESS, payload });
export const getPrioritiesError = (error: Error) => ({ type: types.GET_PRIORITIES_ERROR, payload: { error } });

export const savePriorities = () => ({ type: types.SAVE_PRIORITIES });
export const savePrioritiesSuccess = () => ({ type: types.SAVE_PRIORITIES_SUCCESS });
export const savePrioritiesError = (error: Error) => ({ type: types.SAVE_PRIORITIES_ERROR, payload: { error } });

export const updatePriorities = (payload: { manualPriorities: Array<ICampaignPriority>; systemPriorities: Array<ICampaignPriority> }) => ({
  type: types.UPDATE_PRIORITIES,
  payload,
});

import { isUndefined, isArray, compact, omitBy } from 'ramda-adjunct';

import {
  VIRTUAL_ROOT,
  metaDataVirtualRoot,
  isNestedType,
  FeatureMetadataKeyNames,
  remapDataType,
  DataTypeAndOps,
  readAndRemapDataType,
  optionDictLens,
  pathDictLens,
  dataTypeDictLens,
  originDictLens,
  sortAsc,
} from './common';
import {
  joinWithDot,
  fEmptyArray,
  extractAndRenameKeys,
  deriveAndRenameObject,
  updateWithLens,
  mergeWithLens,
  notMeaningfulString,
  transformShape,
  explodeOjValue,
  fTrue,
  fNot,
  R,
} from '../RamdaHelpers/helpers';

const isAtFeatureLevel = R.equals(FeatureMetadataKeyNames.featureShortName);

const extractFeatureNameAndAlias = grouped =>
  R.map(
    extractAndRenameKeys({
      [FeatureMetadataKeyNames.featureId]: 'id',
      [FeatureMetadataKeyNames.featureShortName]: 'value',
      [FeatureMetadataKeyNames.featureLabel]: 'label',
      [FeatureMetadataKeyNames.featureDesc]: 'desc',
    }),

    R.values(R.map(R.head, grouped)),
  );

const orderedFeatureMetaKeys = R.props(['vertical', 'category', 'featureClass', 'featureShortName'], FeatureMetadataKeyNames);

const operatorsByDataType = dataType => R.path([dataType, 'operators'], DataTypeAndOps) || [];

const isVirtualRoot = R.either(R.isEmpty, R.equals(VIRTUAL_ROOT));

const groupByMetaKey = (key, objects) =>
  R.compose(
    omitBy((_, childKey) => notMeaningfulString(childKey)),
    R.groupBy(R.prop(key)),
  )(objects);

const getChildKeys = R.pipe(R.keys, R.without(['undefined']));
const concatParentKeys = (parentKeys, childKey) => {
  const firstKey = R.head(parentKeys || []) || '';
  const pathKeys = isVirtualRoot(firstKey) ? [] : parentKeys;

  return [...pathKeys, childKey];
};

const subFeaturesOf = superFeature =>
  R.map(
    R.pipe(
      deriveAndRenameObject(
        {
          [FeatureMetadataKeyNames.dataType]: ['options'],
          [FeatureMetadataKeyNames.featureShortName]: ['name', 'key'],
        },

        () => ({ label: 'Operator' }),
      ),

      R.evolve({
        options: R.compose(operatorsByDataType, remapDataType),
        key: subFeature => `${R.prop(FeatureMetadataKeyNames.featureFullName, superFeature)}.${subFeature}`,
      }),
    ),

    R.prop(FeatureMetadataKeyNames.subFeatureValues, superFeature),
  );

const updateOptionDict = R.curry((keys, value, source) => updateWithLens(optionDictLens, joinWithDot(keys), value, source));

const updatePathDict = (key, value, source) => updateWithLens(pathDictLens, key, value, source);

const addOperatorOrSubFeatureToOptionDict = (parentKeys, childKey, groupedChildren, source) => {
  const first = R.head(R.prop(childKey, groupedChildren) || []);
  const dataType = readAndRemapDataType(first);

  const value = isNestedType(dataType) ? subFeaturesOf(first) : operatorsByDataType(dataType);

  return updateOptionDict(concatParentKeys(parentKeys, childKey), value, source);
};

const addOptionsAndPaths = R.curry((parentKeys, childKeys, groupedChildren, source) =>
  R.reduce(
    (acc, childKey) =>
      updatePathDict(childKey, parentKeys, addOperatorOrSubFeatureToOptionDict(parentKeys, childKey, groupedChildren, acc)),
    source,
    childKeys,
  ),
);

const possibleValuesFrom = isNestedFeature =>
  R.cond([
    [() => isNestedFeature, fEmptyArray],
    [
      isUndefined,
      () => {
        /* no-op */
      },
    ],
    [R.either(fNot(isArray), R.isEmpty), fEmptyArray],
    [fTrue, sortAsc],
  ]);

const recurValueItem = ([kv, ...kvPairs]: [unknown, unknown][], acc: unknown[]): unknown[] => {
  if (R.isNil(kv)) {
    return acc;
  }

  const [feature, value] = kv;
  const [dataType, featureId, name, options, subFeatures = []] = transformShape([
    readAndRemapDataType,
    // @ts-expect-error Rambda type issue 😩
    ...R.map(R.prop, [
      FeatureMetadataKeyNames.featureId,
      FeatureMetadataKeyNames.featureShortName,
      FeatureMetadataKeyNames.featureValues,
      FeatureMetadataKeyNames.subFeatureValues,
    ]),
  ])(value);

  const isNestedFeature = isNestedType(dataType);
  const updatedKey = joinWithDot(compact([feature, name]));

  const updatedAcc = [
    ...acc,
    [
      updatedKey,
      {
        label: 'Value', // todo: should move this info elsewhere to cut down redundancy
        originalDataType: dataType,
        dataType,
        featureId,
        options: possibleValuesFrom(isNestedFeature)(options),
      },
    ],
  ];

  return recurValueItem([...R.xprod([updatedKey], isNestedFeature ? subFeatures : []), ...kvPairs], updatedAcc);
};
const transformForDataTypeDict = ([, value]) => recurValueItem([[undefined, R.head(value)]], []);

const updateDataTypeDict = entries => source => mergeWithLens(dataTypeDictLens, explodeOjValue(transformForDataTypeDict)(entries), source);

const dropFeatureValuesFromOriginal = R.compose(R.omit([FeatureMetadataKeyNames.featureValues]), R.head);
const updateOriginDict = entries => source => mergeWithLens(originDictLens, R.map(dropFeatureValuesFromOriginal, entries), source);

const recurFlatten = (metaKeys, parentKeys, childKeys, groupedChildren, result, previousStates) => {
  const [metaKey, ...remainingMetaKeys] = metaKeys;
  const [childKey, ...remainingChildKeys] = childKeys;

  // making sure we haven't exhausted both metaKeys and childKeys
  if (metaKey && childKey) {
    const children = R.prop(childKey, groupedChildren) || [];

    if (R.isEmpty(children)) {
      return recurFlatten(metaKeys, parentKeys, remainingChildKeys, groupedChildren, result, previousStates);
    }

    const newGroupedChildren = groupByMetaKey(metaKey, children);
    const newParentKeys = concatParentKeys(parentKeys, childKey);
    const newChildKeys = getChildKeys(newGroupedChildren);

    const newChildren = isAtFeatureLevel(metaKey) ? extractFeatureNameAndAlias(newGroupedChildren) : newChildKeys;

    const update = R.pipe(updateOptionDict(newParentKeys, newChildren));
    const newResult = update(result);

    type RecurFlattenParams = [
      string[], // metaKeys
      string[], // parentKeys
      string[], // remainingChildKeys
      unknown[], // groupedChildren
      unknown, // newResult
      {
        // previousStates
        prevMetaKeys: string[];
        prevParentKeys: string[];
        prevChildKeys: string[];
        prevGroupedChildren: any[];
      }[], // Array of previous state objects
    ];

    const params: RecurFlattenParams = R.isEmpty(newChildKeys)
      ? [metaKeys, parentKeys, remainingChildKeys, groupedChildren, newResult, previousStates]
      : [
          remainingMetaKeys,
          newParentKeys,
          newChildKeys,
          newGroupedChildren,
          newResult,
          [
            {
              prevMetaKeys: metaKeys,
              prevParentKeys: parentKeys,
              prevChildKeys: remainingChildKeys,
              prevGroupedChildren: groupedChildren,
            },

            ...previousStates,
          ],
        ];

    return recurFlatten(...params);
  }

  const [previousState, ...remainingStates] = previousStates;
  if (previousState) {
    const { prevMetaKeys, prevParentKeys, prevChildKeys, prevGroupedChildren } = previousState;

    // only enrich if we exhaust metaKeys
    const update = metaKey
      ? R.identity
      : //@ts-expect-error Rambda type issue
        R.pipe(...R.map(R.applyTo(groupedChildren), [updateOriginDict, updateDataTypeDict, addOptionsAndPaths(parentKeys, childKeys)]));

    return recurFlatten(prevMetaKeys, prevParentKeys, prevChildKeys, prevGroupedChildren, update(result), remainingStates);
  }
  return result;
};

const buildFeatureInfoDictionariesFrom = metadata => {
  // Parameters for calling recurFlatten()
  const parentKey = '';
  const groupedChildren = {
    [VIRTUAL_ROOT]: metadata,
  };

  const initialResult = {};
  const recurStates = [];

  return recurFlatten(orderedFeatureMetaKeys, parentKey, metaDataVirtualRoot, groupedChildren, initialResult, recurStates);
};

const enrichFeatureReduxState = dicts => {
  const byName = dicts.originDict;

  return {
    dicts,
    byName,
    allNames: R.compose(
      R.map(v => ({ ...v, id: v.feature_id })),
      R.values,
    )(byName),
  };
};

export { recurFlatten, enrichFeatureReduxState, buildFeatureInfoDictionariesFrom };

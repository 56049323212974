import { IActiveUser } from 'app/types/typescript/Users';

type IUserResponse = IActiveUser & {
  storefronts?: string;
  storefrontsMgmtInclude?: string;
  storefrontsMgmtExclude?: string;
};

export const fromApiMe = (data: IUserResponse): IActiveUser => {
  const {
    permittedTenants,
    permissions,
    storefronts,
    storefrontsAndViews,
    storefrontsMgmtInclude,
    storefrontsMgmtExclude,
    name,
    picture,
    email,
    isSecureRoom,
  } = data || {};

  return {
    permittedTenants: permittedTenants || [],
    name: name || '',
    picture: picture || '',
    email: email || '',
    permissions: Array.from(new Set(permissions)),
    storefronts: storefronts ? storefronts.split(',').map((n: string) => Number(n.trim())) : [],
    storefrontsAndViews: storefrontsAndViews || {},
    storefrontsMgmtInclude: storefrontsMgmtInclude ? storefrontsMgmtInclude.split(',').map((n: string) => Number(n.trim())) : [],
    storefrontsMgmtExclude: storefrontsMgmtExclude ? storefrontsMgmtExclude.split(',').map((n: string) => Number(n.trim())) : [],
    isSecureRoom,
  };
};

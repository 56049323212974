import { ComponentType, PureComponent } from 'react';

import { Route, Redirect } from 'react-router-dom';

import withAppConfig from 'app/decorators/withAppConfig';
import { IAppConfig } from 'configs/apps/types';

import { ConnectedUser } from './ducks';

const PATH_UNAUTHORIZED = '/unauthorized';
const PATH_NOT_SUPPORTED = '/not-supported';

interface ProtectedRouteProps {
  requiredPermissions: string[];
  appConfig?: IAppConfig;
  redirect?: string;
  path: string;
  component: ComponentType<any>;
  exact?: boolean;
  deniedInSecureRoom?: boolean;
}

class ProtectedRoute extends PureComponent<ProtectedRouteProps> {
  isSupported = (path: string) => {
    const { appConfig, deniedInSecureRoom } = this.props;
    return (appConfig?.supportedRoutes.includes(path) && !appConfig?.isSecureRoom) || !deniedInSecureRoom;
  };

  render() {
    const { requiredPermissions, appConfig, redirect = PATH_UNAUTHORIZED, path, component: Component, ...rest } = this.props;
    return (
      <ConnectedUser>
        {({ isPermitted }: { isPermitted: (permissions: string[]) => boolean }) => (
          <Route
            {...rest}
            render={({ location, ...props }) =>
              (requiredPermissions.length === 0 || isPermitted(requiredPermissions)) && this.isSupported(path) ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: !this.isSupported(path) ? PATH_NOT_SUPPORTED : redirect,
                    state: { from: location },
                  }}
                />
              )
            }
          />
        )}
      </ConnectedUser>
    );
  }
}

export default withAppConfig(ProtectedRoute);

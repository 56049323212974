import { useState, useEffect, useCallback } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Autocomplete from './Autocomplete';
import { styles } from './styles';

const getSuggestions = (items, value) => {
  if (!items) return undefined;
  return (value || '').length === 0 ? items : items.filter(suggestion => suggestion.toLowerCase().includes(value.toLowerCase()));
};

const AutocompleteWithState = props => {
  const { items, value, handleChange, handleSuggestionSelected, classes } = props;
  const [suggestions, setSuggestions] = useState(getSuggestions(items, value));

  const handleSuggestionsFetchRequested = useCallback(
    ({ value }) => {
      handleChange({ value });
    },
    [handleChange],
  );

  const handleInputChange = useCallback(
    (ev, { newValue }) => {
      handleChange({ value: newValue });
    },
    [handleChange],
  );

  const handleSuggestionSelection = useCallback(
    (ev, { newValue }) => {
      if (handleSuggestionSelected) {
        handleSuggestionSelected({ value: newValue });
      }
    },
    [handleSuggestionSelected],
  );

  useEffect(() => {
    setSuggestions(getSuggestions(items, value));
  }, [items, value]);

  return (
    <Autocomplete
      id="default-autosuggest"
      suggestions={suggestions}
      handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      handleChange={handleInputChange}
      handleSuggestionSelected={handleSuggestionSelection}
      classes={classes}
      {...props}
    />
  );
};

AutocompleteWithState.defaultProps = {
  id: 'default-autosuggest',
};

export default withStyles(styles, { withTheme: true })(AutocompleteWithState);

import React, { useState } from 'react';

import { withKey } from 'app/helpers/FeatureMetadataHelpers/helpers';

import { AndRuleGroup } from './AndRuleGroup';

interface AndRuleGroupWithStateProps {
  data: {
    key: number;
    value: object;
  }[];
  onUpdate: (update: AndRuleGroupWithStateProps['data']) => void;
}

const AndRuleGroupWithState: React.FC<AndRuleGroupWithStateProps> = ({ data = [], onUpdate, ...props }) => {
  const [stateData, setStateData] = useState<AndRuleGroupWithStateProps['data']>(data);

  const updateStateWith = (updatedData: AndRuleGroupWithStateProps['data']) => {
    onUpdate(updatedData);
    setStateData(updatedData);
  };

  const addItem = (item: object) => {
    updateStateWith([...stateData, withKey(item)]);
  };

  const handleChange = (newData: object, index: number) => {
    updateStateWith(stateData.map((item, i) => (i === index ? { ...item, value: newData } : item)));
  };

  const handleDelete = (index: number) => {
    updateStateWith(stateData.filter((_, i) => i !== index));
  };

  const handleCloneOneRule = (index: number) => {
    addItem(stateData[index].value);
  };

  return (
    //@ts-expect-error other props passed from parent don't have types defined yet
    <AndRuleGroup
      {...props}
      data={stateData}
      handleAdd={addItem}
      handleChange={handleChange}
      handleDelete={handleDelete}
      handleCloneOneRule={handleCloneOneRule}
    />
  );
};

export default AndRuleGroupWithState;

import React, { PureComponent } from 'react';

import styled from 'styled-components';

import TestDelivery from 'app/components/TestDelivery';
import withAppConfig from 'app/decorators/withAppConfig';
import _Dropdown from 'app/midgarComponents/Dropdown';
import Grid from 'app/midgarComponents/Grid';
import _Spinner from 'app/midgarComponents/Spinner';
import _TextField from 'app/midgarComponents/TextField';
import { sc } from 'app/styles';
import { getEmailCreativeConfig } from 'configs/apps/creatives/email';
import { IAppConfig } from 'configs/apps/types';
import { channelNameEmail } from 'configs/channels/constants';

type Props = {
  title: string;
  subject?: string;
  sender: string;
  senderName?: string;
  replyTo?: string;
  replyToName?: string;
  allEmailSenders: Array<{
    id: number;
    name: string;
  }>;

  emailSendersLoading?: boolean;
  setSubject: (arg0: string) => void;
  setSender: (arg0: string) => void;
  setSenderName: (arg0: string) => void;
  setReplyTo?: (arg0: string) => void;
  setReplyToName?: (arg0: string) => void;
  appConfig: IAppConfig;
  handleTestDelivery: () => void;
};

class EmailHeader extends PureComponent<Props> {
  static defaultProps = {
    subject: '',
    senderName: '',
    replyToName: '',
    emailSendersLoading: false,
  };

  handleChangeSubject = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { setSubject } = this.props;
    return setSubject && setSubject(ev.target?.value);
  };

  handleSelectSenderFrom = (option: { id: number; label: string }) => {
    const { setSender } = this.props;
    return setSender && setSender(option.label);
  };

  handleSelectSenderName = (senderName: string) => {
    const { setSenderName } = this.props;
    return setSenderName && setSenderName(senderName);
  };

  handleSelectReplyTo = (option: { id: number; label: string }) => {
    const { setReplyTo } = this.props;
    return setReplyTo && setReplyTo(option.label);
  };

  handleChangeReplyToName = (replyToName: string) => {
    const { setReplyToName } = this.props;
    return setReplyToName && setReplyToName(replyToName);
  };

  render() {
    const {
      title,
      subject,
      sender,
      senderName,
      replyTo,
      replyToName,
      allEmailSenders,
      emailSendersLoading,
      appConfig,
      handleTestDelivery,
    } = this.props;

    const emailSenderOptions = allEmailSenders.map(({ id, name }) => ({ id, label: name }));
    const findSenderOption = (
      all: Array<{
        id: number;
        label: string;
      }>,

      value?: string,
    ) => all.find(option => option.label === value);

    const emailCreativeConfig = getEmailCreativeConfig(appConfig);
    const { senderNames, useReplyTo } = emailCreativeConfig;

    return (
      <Container data-qa="email-header">
        <Title>{title}</Title>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <SubjectRow
              required
              label="Subject for email"
              value={subject}
              onChange={this.handleChangeSubject}
              name="subject"
              data-qa="subject-for-email"
            />

            {emailSendersLoading ? (
              <Spinner size={40} />
            ) : (
              <>
                <DropdownGroup>
                  <Dropdown
                    required
                    value={findSenderOption(emailSenderOptions, sender)}
                    options={emailSenderOptions}
                    label="Send from"
                    onChange={this.handleSelectSenderFrom}
                  />

                  <Dropdown required value={senderName} options={senderNames} onChange={this.handleSelectSenderName} label="Sender name" />
                </DropdownGroup>
                {useReplyTo && (
                  <DropdownGroup>
                    <Dropdown
                      required
                      value={findSenderOption(emailSenderOptions, replyTo)}
                      options={emailSenderOptions}
                      label="Reply to"
                      onChange={this.handleSelectReplyTo}
                    />

                    <Dropdown
                      required
                      label="Reply name"
                      value={replyToName}
                      options={senderNames}
                      onChange={this.handleChangeReplyToName}
                    />
                  </DropdownGroup>
                )}
              </>
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <TestDeliveryContainer>
              <TestDelivery buttonText="Send Test Email" deliveryChannel={channelNameEmail} handleSend={handleTestDelivery} />
            </TestDeliveryContainer>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withAppConfig(EmailHeader);

const Container = styled.section`
  width: 100%;
  margin-top: 2.5rem;
`;

const Dropdown = styled(_Dropdown)`
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
`;

const DropdownGroup = styled.section`
  display: flex;
  margin-bottom: 1rem;
`;

const Spinner = styled(_Spinner)`
  position: relative;
  top: 20%;
`;

const SubjectRow = styled(_TextField)`
  width: 100%;
  margin-bottom: 1rem;
`;

const TestDeliveryContainer = styled.section`
  padding: 0 2rem;
`;

const Title = styled.section`
  color: ${sc.headingColor};
  font-size: ${sc.fontSizeSmaller};
  line-height: 1.33;
  letter-spacing: 0.8px;
  font-weight: 800;
  margin-bottom: 1.5rem;
`;

// == Segments ==
const SEGMENT_READ_ONLY = 'SegmentReadOnly';
const SEGMENT_CREATE = 'SegmentCreate';
const SEGMENT_DOWNLOAD = 'SegmentDownload';
const SEGMENT_UPLOAD = 'SegmentUpload';
const CAMPAIGN_MERCHANT_PUSH_DRAFT = 'CampaignMerchantPushDraft';
const CAMPAIGN_MERCHANT_EMAIL_DRAFT = 'CampaignMerchantEmailDraft';
const MASTERPLAN_MERCHANT_READ_ONLY = 'MasterplanMerchantReadOnly';
const CAMPAIGN_MERCHANT_PUSH_APPROVE_ONLY = 'CampaignMerchantPushApproveOnly';
const CAMPAIGN_MERCHANT_EMAIL_APPROVE_ONLY = 'CampaignMerchantEmailApproveOnly';
const MASTERPLAN_MERCHANT_EDIT = 'MasterplanMerchantEdit';

// == Campaigns ==
const CAMPAIGN_READ_ONLY = 'CampaignReadOnly';

// == Banner campaign permissions
const CAMPAIGN_BANNER_CREATE = 'CampaignBannerCreate';
const CAMPAIGN_BANNER_DRAFT = 'CampaignBannerDraft';
const allBannerPermissions = [CAMPAIGN_BANNER_CREATE, CAMPAIGN_BANNER_DRAFT];

// == Cashback campaign permissions
const CAMPAIGN_CASHBACK_CREATE = 'CampaignCashbackCreate';
const CAMPAIGN_CASHBACK_DRAFT = 'CampaignCashbackDraft';
const CAMPAIGN_CASHBACK_APPROVE = 'CampaignCashbackApproveOnly';
const allCashbackPermissions = [CAMPAIGN_CASHBACK_CREATE, CAMPAIGN_CASHBACK_DRAFT, CAMPAIGN_CASHBACK_APPROVE];

// == Email campaign permissions
const CAMPAIGN_EMAIL_CREATE = 'CampaignEmailCreate';
const CAMPAIGN_EMAIL_DRAFT = 'CampaignEmailDraft';
const CAMPAIGN_EMAIL_APPROVE = 'CampaignEmailApproveOnly';
const allEmailPermissions = [CAMPAIGN_EMAIL_CREATE, CAMPAIGN_EMAIL_DRAFT, CAMPAIGN_EMAIL_APPROVE];

// == Timeline campaign permissions
const CAMPAIGN_TIMELINE_CREATE = 'CampaignTimelineCreate';
const CAMPAIGN_TIMELINE_DRAFT = 'CampaignTimelineDraft';
const CAMPAIGN_TIMELINE_APPROVE = 'CampaignTimelineApproveOnly';
const allTimelinePermissions = [CAMPAIGN_TIMELINE_CREATE, CAMPAIGN_TIMELINE_DRAFT, CAMPAIGN_TIMELINE_APPROVE];

// == Promo campaign permissions
const CAMPAIGN_PROMO_CREATE = 'CampaignPromoCreate';
const CAMPAIGN_PROMO_DRAFT = 'CampaignPromoDraft';
const allPromoPermissions = [CAMPAIGN_PROMO_CREATE, CAMPAIGN_PROMO_DRAFT];

// == Push campaign permissions
const CAMPAIGN_PUSH_CREATE = 'CampaignPushCreate';
const CAMPAIGN_PUSH_DRAFT = 'CampaignPushDraft';
const CAMPAIGN_PUSH_APPROVE = 'CampaignPushApproveOnly';
const allPushPermissions = [CAMPAIGN_PUSH_CREATE, CAMPAIGN_PUSH_DRAFT, CAMPAIGN_PUSH_APPROVE];

// == Channel Template -Specific ==
// Email Templates
const TEMPLATE_DESIGN_USER = 'TemplateDesignUser';
const TEMPLATE_READ_ONLY = 'TemplateReadOnly';
// Banner Debug
const BANNER_DEBUG_EDIT = 'BannerDebugEdit';
const BANNER_DEBUG_READ_ONLY = 'BannerDebugReadOnly';

// == Master Plan ==
const MASTERPLAN_EDIT = 'MasterplanEdit';
const MASTERPLAN_READ_ONLY = 'MasterplanReadOnly';

// == User Management ==
const USERS_READ_ONLY = 'UsersReadOnly';

// == Banner Management ==
const BANNER_MANAGEMENT_EDIT = 'BannerManagementEdit';
const BANNER_MANAGEMENT_READ_ONLY = 'BannerManagementReadOnly';
const BANNER_MANAGEMENT_STOREFRONT_EDIT_ALL = 'BannerManagementStorefrontEditAll';
const BANNER_MANAGEMENT_STOREFRONT_INCLUDE = 'BannerManagementStorefrontInclude';
const BANNER_MANAGEMENT_STOREFRONT_EXCLUDE = 'BannerManagementStorefrontExclude';

// == Cashback Management ==
const CASHBACK_MANAGEMENT_READ_ONLY = 'CashbackManagementReadOnly';
const CASHBACK_MANAGEMENT_BANNER_CREATE = 'CashbackManagementBannerCreate';
const CASHBACK_MANAGEMENT_EVENT_SCHEMA_CREATE = 'CashbackManagementEventSchemaCreate';
const CASHBACK_MANAGEMENT_GLOBAL_LIMIT_CREATE = 'CashbackManagementGlobalLimitCreate';
const CASHBACK_MANAGEMENT_STACKABLE_GROUP_CREATE = 'CashbackManagementStackableGroupCreate';
const CASHBACK_MANAGEMENT_USER_BUDGET_TOKEN_CREATE = 'CashbackManagementUserBudgetTokenCreate';
const allCashbackManagementPermissions = [
  CASHBACK_MANAGEMENT_READ_ONLY,
  CASHBACK_MANAGEMENT_BANNER_CREATE,
  CASHBACK_MANAGEMENT_EVENT_SCHEMA_CREATE,
  CASHBACK_MANAGEMENT_GLOBAL_LIMIT_CREATE,
  CASHBACK_MANAGEMENT_STACKABLE_GROUP_CREATE,
  CASHBACK_MANAGEMENT_USER_BUDGET_TOKEN_CREATE,
];

// == Super User ==
const SUPER_ADMIN = 'SuperAdmin';

// == Internal Tester
const INTERNAL_TEST = 'InternalTest';

const defaultStorefrontsMgmtInclude = '48';
const defaultStorefrontsMgmtExclude = '50';

// == Segments ==
const segmentDownloadPermissions = [SEGMENT_DOWNLOAD];
const segmentUploadPermissions = [SEGMENT_UPLOAD];
const segmentCreationPermissions = [SEGMENT_CREATE];
const segmentReadPermissions = [SEGMENT_READ_ONLY, ...segmentCreationPermissions, ...segmentUploadPermissions];
const allSegmentPermissions = [SEGMENT_READ_ONLY, SEGMENT_CREATE, SEGMENT_DOWNLOAD, SEGMENT_UPLOAD];

// == Campaigns ==
const campaignCreationPermissions = [
  CAMPAIGN_BANNER_CREATE,
  CAMPAIGN_CASHBACK_CREATE,
  CAMPAIGN_EMAIL_CREATE,
  CAMPAIGN_PROMO_CREATE,
  CAMPAIGN_PUSH_CREATE,
  CAMPAIGN_TIMELINE_CREATE,
];

const merchantCampaignCreationPermissions = [
  SEGMENT_CREATE,
  SEGMENT_DOWNLOAD,
  SEGMENT_UPLOAD,
  CAMPAIGN_MERCHANT_PUSH_DRAFT,
  CAMPAIGN_MERCHANT_EMAIL_DRAFT,
  MASTERPLAN_MERCHANT_READ_ONLY,
];
const merchantCampaignApprovalPermissions = [
  SEGMENT_CREATE,
  SEGMENT_DOWNLOAD,
  SEGMENT_UPLOAD,
  CAMPAIGN_MERCHANT_PUSH_APPROVE_ONLY,
  CAMPAIGN_MERCHANT_EMAIL_APPROVE_ONLY,
  MASTERPLAN_MERCHANT_EDIT,
];

const campaignDraftPermissions = [
  CAMPAIGN_BANNER_DRAFT,
  CAMPAIGN_CASHBACK_DRAFT,
  CAMPAIGN_EMAIL_DRAFT,
  CAMPAIGN_PROMO_DRAFT,
  CAMPAIGN_PUSH_DRAFT,
  CAMPAIGN_TIMELINE_DRAFT,
];

const campaignApprovalPermissions = [CAMPAIGN_CASHBACK_APPROVE, CAMPAIGN_EMAIL_APPROVE, CAMPAIGN_PUSH_APPROVE, CAMPAIGN_TIMELINE_APPROVE];

const campaignReadPermissions = [
  CAMPAIGN_READ_ONLY,
  ...campaignCreationPermissions,
  ...campaignDraftPermissions,
  ...campaignApprovalPermissions,
];

// == Channel Specific ==
// Email Templates
const templateCreationPermissions = [TEMPLATE_DESIGN_USER];
const templateReadPermissions = [TEMPLATE_READ_ONLY, ...templateCreationPermissions];
const allTemplatePermssions = [TEMPLATE_DESIGN_USER, TEMPLATE_READ_ONLY];

// Banner Debug
const bannerDebugEditPermissions = [BANNER_DEBUG_EDIT];
const bannerDebugReadPermissions = [BANNER_DEBUG_READ_ONLY, ...bannerDebugEditPermissions];
const allBannerDebugPermissions = [BANNER_DEBUG_EDIT, BANNER_DEBUG_READ_ONLY];

// == Master Plan ==
const masterplanEditPermissions = [MASTERPLAN_EDIT];
const masterplanReadPermissions = [MASTERPLAN_READ_ONLY, ...masterplanEditPermissions];
const allMasterPlanPermssions = [MASTERPLAN_EDIT, MASTERPLAN_READ_ONLY];

// == User Management ==
const usersEditPermissions = [SUPER_ADMIN];
const usersReadPermissions = [USERS_READ_ONLY, ...usersEditPermissions];
const allUsersPermissions = [USERS_READ_ONLY];

// == Banner Management ==
const bannerManagementEditPermissions = [BANNER_MANAGEMENT_EDIT];
const bannerManagementReadPermissions = [BANNER_MANAGEMENT_READ_ONLY, ...bannerManagementEditPermissions];
const bannerManagementStorefrontEditPermissions = [
  BANNER_MANAGEMENT_STOREFRONT_EDIT_ALL,
  BANNER_MANAGEMENT_STOREFRONT_INCLUDE,
  BANNER_MANAGEMENT_STOREFRONT_EXCLUDE,
];

const bannerManagementStorefrontReadPermissions = [...bannerManagementReadPermissions, ...bannerManagementStorefrontEditPermissions];
const allBannerManagementPermissions = [
  BANNER_MANAGEMENT_EDIT,
  BANNER_MANAGEMENT_READ_ONLY,
  BANNER_MANAGEMENT_STOREFRONT_EDIT_ALL,
  BANNER_MANAGEMENT_STOREFRONT_INCLUDE,
  BANNER_MANAGEMENT_STOREFRONT_EXCLUDE,
];

export {
  // == Segments ==
  SEGMENT_READ_ONLY,
  SEGMENT_CREATE,
  SEGMENT_DOWNLOAD,
  SEGMENT_UPLOAD,
  CAMPAIGN_EMAIL_APPROVE,

  // == Banner campaign permissions ==
  CAMPAIGN_BANNER_CREATE,
  CAMPAIGN_BANNER_DRAFT,
  allBannerPermissions,

  // == Email permissions ==
  CAMPAIGN_EMAIL_CREATE,
  CAMPAIGN_EMAIL_DRAFT,
  allEmailPermissions,

  // == Timeline permissions ==
  CAMPAIGN_TIMELINE_CREATE,
  CAMPAIGN_TIMELINE_DRAFT,
  CAMPAIGN_TIMELINE_APPROVE,
  allTimelinePermissions,
  CAMPAIGN_CASHBACK_CREATE,
  CAMPAIGN_CASHBACK_DRAFT,
  CAMPAIGN_CASHBACK_APPROVE,

  // == Promo campaign permissions
  CAMPAIGN_PROMO_CREATE,
  CAMPAIGN_PROMO_DRAFT,
  allPromoPermissions,

  // == Push campaign permissions
  CAMPAIGN_PUSH_CREATE,
  CAMPAIGN_PUSH_DRAFT,
  CAMPAIGN_PUSH_APPROVE,
  CAMPAIGN_READ_ONLY,
  allPushPermissions,

  // == Channel Template -Specific ==
  // Email Templates
  TEMPLATE_DESIGN_USER,
  TEMPLATE_READ_ONLY,
  // Banner Debug
  BANNER_DEBUG_EDIT,
  BANNER_DEBUG_READ_ONLY,

  // == Master Plan ==
  MASTERPLAN_EDIT,
  MASTERPLAN_READ_ONLY,

  // == User Management ==
  USERS_READ_ONLY,

  // == Banner Management ==
  BANNER_MANAGEMENT_EDIT,
  BANNER_MANAGEMENT_READ_ONLY,
  BANNER_MANAGEMENT_STOREFRONT_EDIT_ALL,
  BANNER_MANAGEMENT_STOREFRONT_INCLUDE,
  BANNER_MANAGEMENT_STOREFRONT_EXCLUDE,

  // == Cashback Management ==
  CASHBACK_MANAGEMENT_READ_ONLY,
  CASHBACK_MANAGEMENT_BANNER_CREATE,
  CASHBACK_MANAGEMENT_EVENT_SCHEMA_CREATE,
  CASHBACK_MANAGEMENT_GLOBAL_LIMIT_CREATE,
  CASHBACK_MANAGEMENT_STACKABLE_GROUP_CREATE,
  CASHBACK_MANAGEMENT_USER_BUDGET_TOKEN_CREATE,
  allCashbackManagementPermissions,

  // == Merchant permission ==
  CAMPAIGN_MERCHANT_PUSH_DRAFT,
  CAMPAIGN_MERCHANT_EMAIL_DRAFT,
  MASTERPLAN_MERCHANT_READ_ONLY,
  CAMPAIGN_MERCHANT_PUSH_APPROVE_ONLY,
  CAMPAIGN_MERCHANT_EMAIL_APPROVE_ONLY,
  MASTERPLAN_MERCHANT_EDIT,

  // == Super User ==
  SUPER_ADMIN,

  // == Internal Tester
  INTERNAL_TEST,
  defaultStorefrontsMgmtInclude,
  defaultStorefrontsMgmtExclude,

  // == Segments ==
  segmentDownloadPermissions,
  segmentUploadPermissions,
  segmentCreationPermissions,
  segmentReadPermissions,
  allSegmentPermissions,

  // == Campaigns ==
  campaignCreationPermissions,
  campaignDraftPermissions,
  campaignApprovalPermissions,
  campaignReadPermissions,
  // == Channel Specific ==
  // Email Templates
  templateCreationPermissions,
  templateReadPermissions,
  allTemplatePermssions,

  // Banner Debug
  bannerDebugEditPermissions,
  bannerDebugReadPermissions,
  allBannerDebugPermissions,

  // == Master Plan ==
  masterplanEditPermissions,
  masterplanReadPermissions,
  allMasterPlanPermssions,

  // == User Management ==
  usersEditPermissions,
  usersReadPermissions,
  allUsersPermissions,

  // == Banner Management ==
  bannerManagementEditPermissions,
  bannerManagementReadPermissions,
  bannerManagementStorefrontEditPermissions,
  bannerManagementStorefrontReadPermissions,
  allBannerManagementPermissions,
  allCashbackPermissions,

  // == Merchant permissions ==
  merchantCampaignCreationPermissions,
  merchantCampaignApprovalPermissions,
};

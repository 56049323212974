import { CmaApiError } from 'app/types/CmaError';

const genericErrorMsg = ({ status, statusText }) =>
  `Sorry, something has gone wrong. [STATUS=${status}${statusText ? `: ${statusText}` : ''}]`;

export const catchErrors = async (res: Response, req: { reqBody?: any } = {}) => {
  if (res.ok) {
    return res;
  }

  if (res.status === 401) {
    if (process.env.NODE_ENV === 'development') {
      return res;
    }

    return (window.location.href = '/auth-error?type=invalid-user');
  }

  const contentType = res.headers.get('content-type');

  if (typeof contentType === 'string' && contentType.includes('application/json')) {
    const result = await res.json();
    const { apierror, errorMessage } = result;

    const errorRes = apierror || errorMessage;

    if (errorRes) {
      if (typeof errorRes === 'string') throw new Error(errorRes);
      throw new Error(JSON.stringify(errorRes));
    }

    throw new Error(genericErrorMsg(res));
  } else if (typeof contentType === 'string' && contentType.includes('text/plain')) {
    throw new Error(genericErrorMsg(res));
  } else {
    // TODO: Handle a text response body
    // const text = await res.text();
    // todo: fix this after we know what happens
    throw new Error(genericErrorMsg(res));
  }
};

/**
 * Converts a generic `Error` object into a `CmaApiError` object.
 *
 * If the error message is stringified JSON that can be interpreted as having the shape of `CmaApiError`, then this `CmaApiError` object is returned.
 *
 * Otherwise, a miscellaneous `CmaApiError` object is constructed, where the message from the source `Error` object is the message in the resulting
 * `CmaApiError` object, and the `status` = `MISC_ERROR`.
 *
 * @param {Error} err
 * @returns {CmaApiError}
 */
export const asApiError = (err: Error): CmaApiError => {
  const { message } = err;
  const rawObj = JSON.parse(message);
  if (rawObj && typeof rawObj === 'object') {
    const { apierror: apiError } = rawObj;
    const detailObj = apiError && typeof apiError === 'object' ? apiError : rawObj;
    // eslint-disable-next-line no-prototype-builtins
    if (detailObj.hasOwnProperty('status') && detailObj.hasOwnProperty('message')) {
      return detailObj as CmaApiError;
    }
  }

  return {
    status: 'MISC_ERROR',
    message,
  };
};

export const asSingleLineError = (err: CmaApiError): string => `[${err.status}: ${err.message}] ${err.details || ''}`;

import { IApiVariant } from 'app/api/bannerManagementV2/creatives/types';
import { IAppVersionFilter, ICreativeCategoryWeight, IVariant } from 'app/types/BannerManagement';
import { ICategory } from 'app/types/Category';

export const emptyForm = () => ({
  populated: false,
  errors: [],
  variants: {
    '0': emptyVariant(),
  },
});

export const emptyVariant = (): any => ({
  alternateImageFile: null,
  alternateImageUrl: null,
  appVersions: { '0': {} },
  engageCategories: { '0': {} },
  imageFile: null,
  imageUrl: null,
  labelDetails: {},
  locations: {
    includedCities: [],
    excludedCities: [],
    includedStates: [],
    excludedStates: [],
  },

  metadata: {},
  personalizationMetadata: {},
});

export const populateForm = (creative: Record<string, any>, options: Record<string, any> = {}) => {
  const resultForm = Object.assign(emptyForm(), creative);

  if (options.new) {
    delete resultForm.id;
  }

  resultForm.platform = creative.platform.map(x => ({
    id: x.toLowerCase(),
    name: x,
  }));

  resultForm.site = creative.site.map(x => ({
    id: x.toLowerCase(),
    name: x,
  }));

  resultForm.variants = populateFormVariants(creative.variants, {
    categories: options.categories,
  });

  return resultForm;
};

export const populateFormVariants = (variants: Array<IApiVariant>, options: Record<string, any> = {}): Array<IVariant> => {
  const resultVariants = [];
  const categories = options.categories || ([] as Array<ICategory>);

  Array.isArray(variants) &&
    variants.forEach((variant, idx) => {
      const variantAppVersions = variant.appVersionFilter
        ? variant.appVersionFilter.reduce((res: Record<string, IAppVersionFilter>, x, index) => {
            res[index] = x;
            return res;
          }, {})
        : { '0': {} };

      const variantEngageCategories = variant.categoryWeights2
        ? variant.categoryWeights2.reduce((res: Record<string, ICreativeCategoryWeight>, catWeight, index) => {
            const { categoryId } = catWeight;
            const { weight } = catWeight;
            const engageCategory = categories.find((cat: ICategory) => cat.id === categoryId) || {};

            res[index] = {
              category: {
                id: categoryId,
                name: `${engageCategory.level} - ${engageCategory.name}`,
              },

              value: `${engageCategory.level} - ${engageCategory.name}`,
              weight,
            };

            return res;
          }, {})
        : { '0': {} };

      type locationType = Array<{ id: string; name: string; type: string }>;
      const variantLocations: {
        includedCities: locationType;
        includedStates: locationType;
        excludedCities: locationType;
        excludedStates: locationType;
      } = {
        includedCities: [],
        includedStates: [],
        excludedCities: [],
        excludedStates: [],
      };

      variant.locationFilter &&
        variant.locationFilter.forEach(({ filterType, locationType, location }: Record<string, any>) => {
          if (filterType === 'INCLUDE') {
            if (locationType === 'City') {
              variantLocations.includedCities.push({
                id: location.toLowerCase(),
                name: location,
                type: 'City',
              });
            } else if (locationType === 'State') {
              variantLocations.includedStates.push({
                id: location.toLowerCase(),
                name: location,
                type: 'State',
              });
            }
          } else if (filterType === 'EXCLUDE') {
            if (locationType === 'City') {
              variantLocations.excludedCities.push({
                id: location.toLowerCase(),
                name: location,
                type: 'City',
              });
            } else if (locationType === 'State') {
              variantLocations.excludedStates.push({
                id: location.toLowerCase(),
                name: location,
                type: 'State',
              });
            }
          }
        });

      resultVariants[idx] = Object.assign(emptyVariant(), variant, {
        alternateImageUrl: variant.alternateImage,
        appVersions: variantAppVersions,
        engageCategories: variantEngageCategories,
        imageUrl: variant.image,
        locations: variantLocations,
        metadata: variant.metadata,
        personalizationMetadata: variant.personalizationMetadata,
      });
    });

  return resultVariants;
};

import moment from 'moment';
import { tryCatch } from 'ramda';

import { cloneSegment as clone, enhanceSegment, segmentTypeFlags } from 'app/features/Segments2/utils';
import { timeZone } from 'app/helpers/AppEnvHelpers/helpers';
import { displayError, displaySuccess, displayWarning } from 'app/helpers/NotificationHelpers/helpers';
import { parseDateTimeUtc } from 'app/utilities/date';
import * as fetch from 'app/utilities/http';

import * as actions from './actions';
import * as selectors from './selectors';
import { mapIds } from '../../helpers';

const api = {
  get: '/audience/:id',
  post: '/audience/new-from-csv',
  postRuleBased: '/audience/schedule-extraction',
  postCompositionBased: '/audience/schedule-composition',
  fileUpload: '/files/upload',
  countByQuery: '/audience/count',
};

const handleError = ({ message: error }, context = 'An error occurred') => {
  const { debugMessage, message, status } = tryCatch(JSON.parse, () => ({ message: error }))(error);

  displayError(`${context}: ${debugMessage || message || status}`);
};

const fetchSegment = async (id, state) => {
  const {
    audience: {
      audiences: { byId },
    },
  } = state;
  const audience = byId[id];
  return audience ? Promise.resolve(audience) : fetch.get(api.get.replace(':id', id));
};

const processResponse = (audienceData, getState) => {
  try {
    const data = JSON.parse(audienceData.audienceCreationRules);
    const defaultFormat = 'YYYY-MM-DDTHH:mm:ssz';
    const {
      audience: {
        audienceRules: {
          data: {
            dicts: { operatorTypes },
          },
        },
      },

      user: { email },
    } = getState();
    const types = {
      count: 'Integer',
      timePeriod: 'TimePeriod',
      bankCode: 'BankCode',
      numericRange: 'NumericRange',
    };

    const features = [];
    const selectorData = [];

    data.forEach(item => {
      features.push(item.querySelector);
      const rebuildData = [];

      item.queryExpressions.forEach((query: { [key: string]: any }) => {
        const queryData = Object.entries(query);
        const similarFields = {
          op: operatorTypes[queryData[0][1]],
          operands: [queryData[1][0], queryData[1][1]],
        };

        if (queryData[1][0] === 'timePeriod') {
          rebuildData.push({
            ...similarFields,
            operands: [
              queryData[1][0],
              {
                startOfPeriod: moment(queryData[1][1].startOfPeriod).tz(timeZone()).unix(),
                endOfPeriod: moment(queryData[1][1].endOfPeriod).tz(timeZone()).unix(),
              },
            ],

            dataType: types[queryData[1][0]] || 'TimePeriod',
          });
        } else if (queryData[1][0] === 'numericRange') {
          rebuildData.push({
            ...similarFields,
            operands: [
              queryData[1][0],
              {
                startOfRange: queryData[1][1].startOfRange,
                endOfRange: queryData[1][1].endOfRange,
              },
            ],

            dataType: types[queryData[1][0]] || 'Integer',
          });
        } else if (queryData[1][0] === 'bankCode') {
          rebuildData.push({
            ...similarFields,
            operands: [queryData[1][0], queryData[1][1].code],
            dataType: types[queryData[1][0]] || 'BankCode',
          });
        } else {
          rebuildData.push({
            ...similarFields,
            dataType: types[queryData[1][0]] || 'Integer',
          });
        }
      });
      selectorData.push({
        op: 'AND',
        type: 'bool',
        parentFeature: item.querySelector,
        operands: rebuildData,
      });
    });
    const includedRules = [
      {
        expression: {
          op: 'OR',
          type: 'bool',
          operands: [
            {
              op: 'AND',
              type: 'bool',
              operands: selectorData,
            },
          ],
        },

        features: features,
        createdBy: email,
        id: 1,
      },
    ];

    return {
      includedRules: includedRules,
      firstExtractionAt: moment(audienceData.firstExtractionAt).format(defaultFormat),
      extractionIntervalInHours: audienceData.extractionIntervalInHours,
      willBeUpdatedUntil: moment(audienceData.willBeUpdatedUntil).format(defaultFormat),
    };
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getMetaData = async audience => {
  try {
    const audienceCompositionRules = JSON.parse(audience.audienceCompositionRules);
    const allAudienceIds = [...audienceCompositionRules?.unionAudiences, ...audienceCompositionRules?.excludedAudiences];
    const allAudienceIdsRes = [];
    await fetch
      .post('/audience/list-metadata', allAudienceIds)
      .then(data => {
        data.filter(item => (allAudienceIdsRes[item.audienceId] = item));
        audience = {
          ...audience,
          compositionIntervalInHours: audience.extractionIntervalInHours.toString(),
          firstExtractionAt: moment(audience.lastExtractionAt).format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          willBeUpdatedUntil: moment(audience.nextExtractionAt).format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          includedSegments: audienceCompositionRules?.unionAudiences.map(item => allAudienceIdsRes[item] || []),
          excludedSegments: audienceCompositionRules?.excludedAudiences.map(item => allAudienceIdsRes[item] || []),
        };

        return allAudienceIdsRes;
      })
      .catch(error => {
        console.log('errorerror = ', error);
        return audience;
      });
    return audience;
  } catch (error) {
    return audience;
  }
};

const handleCount = (rule, dispatch) => {
  fetch
    .put(api.countByQuery, rule)
    .then(count => dispatch(actions.countSuccess(count)))
    .catch(error => {
      dispatch(actions.countFailure(error));
    });
};

const getSegment = id => async (dispatch, getState) => {
  dispatch(actions.get());
  try {
    let audience = await fetchSegment(id, getState());
    if (audience.audienceType === 'RULE_BASED') audience = { ...audience, ...processResponse(audience, getState) };
    if (audience.audienceType === 'COMPOSITION') audience = await getMetaData(audience);

    return dispatch(actions.getSuccess(audience));
  } catch (e) {
    displayError('Error getting segment');
    return dispatch(actions.getFail(e));
  }
};

const cloneSegment = (id, featureDicts) => async (dispatch, getState) => {
  dispatch(actions.get());
  try {
    const baseSegment = await fetchSegment(id, getState());
    let audience = baseSegment;

    if (audience.audienceType === 'RULE_BASED')
      audience = enhanceSegment(clone({ ...baseSegment, ...processResponse(baseSegment, getState) }), featureDicts);
    if (audience.audienceType === 'COMPOSITION') audience = await getMetaData(audience);
    return dispatch(actions.getSuccess(audience));
  } catch (e) {
    displayError('Error cloning segment');
    return dispatch(actions.getFail(e));
  }
};

const newSegment = () => dispatch => dispatch(actions.newSegment());

const getExtractionData = state => {
  const dtFormat = 'YYYY-MM-DD HH:mm:ss';
  return {
    firstExtractionAt: parseDateTimeUtc(state.firstExtractionAt).format(dtFormat),
    willBeUpdatedUntil: parseDateTimeUtc(state.willBeUpdatedUntil).format(dtFormat),
  };
};

const ruleSegmentBuilder = (ruleData, operatorTypes, expressionsDict) => {
  try {
    const creationRules = [];

    const getFormattedExpressionValue = (expressionCode, expressionValue) => {
      switch (expressionCode) {
        case 'timePeriod':
          const dtFormat = 'YYYY-MM-DD HH:mm:ss';

          return {
            startOfPeriod: moment.unix(expressionValue.startOfPeriod).tz(timeZone()).format(dtFormat),
            endOfPeriod: moment.unix(expressionValue.endOfPeriod).tz(timeZone()).format(dtFormat),
          };

        default:
          return expressionValue;
      }
    };

    ruleData.forEach(item => {
      const queryExpressionsData = [];

      item.operands.forEach(expression => {
        const expressionCode = expression.operands[0];
        const expressionValue = expression.operands[1];
        const expressionOperator = operatorTypes[expression.op];
        const expressionDefinition = expressionsDict[expressionCode];
        const expressionData = {};

        const formattedExpressionValue = getFormattedExpressionValue(expressionCode, expressionValue);

        if (expressionDefinition) {
          expressionData[expressionDefinition.operatorCode] = expressionOperator;
          expressionData[expressionDefinition.code] = formattedExpressionValue;
        }

        queryExpressionsData.push(expressionData);
      });

      creationRules.push({
        querySelector: item.parentFeature,
        queryExpressions: queryExpressionsData,
      });
    });

    return creationRules;
  } catch (error) {
    return [];
  }
};

const postSegment = history => async (dispatch, getState) => {
  const {
    audience: {
      audience: {
        audienceType,
        name,
        description,
        tags,
        includedRules,
        csvFile,
        extractionIntervalInHours,
        compositionIntervalInHours,
        includedSegments = [],
        excludedSegments = [],
        firstExtractionAt,
        willBeUpdatedUntil,
        useCaseType,
      },

      audienceRules: {
        data: {
          dicts: { operatorTypes, expressionsDict },
        },
      },
    },

    user: { email },
  } = getState();

  dispatch(actions.post());

  const common = {
    tags,
    name: name.trim(),
    description: description.trim(),
    creatorEmail: email,
    useCaseType,
  };

  let apiType = api.post;
  let audience = {
    ...common,
  };

  let audiencePostAPI = fetch.post;
  if (audienceType === segmentTypeFlags.CSV_BASED && !csvFile) {
    dispatch(actions.addCsvFail('Error loading CSV'));
    return displayWarning('Please select .csv file!');
  }

  switch (audienceType) {
    case segmentTypeFlags.CSV_BASED:
      audiencePostAPI = fetch.fileUpload;
      const fileForm = new FormData();
      fileForm.append('audience-tags', tags.map(item => item.name).join(', '));
      fileForm.append('audience-name', name.trim());
      fileForm.append('audience-description', description.trim());
      fileForm.append('creatorEmail', email);
      fileForm.append('file-data', csvFile);
      fileForm.append('useCaseType', useCaseType);
      audience = fileForm;
      break;
    case segmentTypeFlags.RULE_BASED:
      const ruleData = includedRules[0].expression.operands[0].operands || [];
      apiType = api.postRuleBased;

      audience = {
        ...audience,
        rulesGroupCreationRequest: {
          audienceCreationRules: ruleSegmentBuilder(ruleData, operatorTypes, expressionsDict),
        },

        extractionIntervalInHours: extractionIntervalInHours,
        ...getExtractionData({ firstExtractionAt, willBeUpdatedUntil }),
      };

      break;
    case segmentTypeFlags.COMPOSITION:
      apiType = api.postCompositionBased;
      audience = {
        ...audience,
        compositionRules: {
          unionAudiences: mapIds(includedSegments),
          excludedAudiences: mapIds(excludedSegments),
        },

        compositionIntervalInHours: compositionIntervalInHours,
        ...getExtractionData({ firstExtractionAt, willBeUpdatedUntil }),
      };

      break;
    default:
      break;
  }

  try {
    const { audienceId } = await audiencePostAPI(apiType, audience);
    displaySuccess('Audience created');
    dispatch(actions.postSuccess(audienceId));
    return history.push(`/audience/${audienceId}`);
  } catch (e) {
    handleError(e, 'Error creating segment');
    return dispatch(actions.postFail(e));
  }
};

const uploadFile = file => async dispatch => {
  dispatch(actions.addCsv());
  const csvName = file.name;
  try {
    return dispatch(actions.addCsvSuccess({ csvFile: file, csvName }));
  } catch (e) {
    handleError(e, 'Error uploading CSV');
    return dispatch(actions.addCsvFail('Error loading CSV'));
  }
};

const setIncludedRules = (ruleExpression, ruleData, errors) => dispatch =>
  dispatch(actions.setIncludedRules(ruleExpression, ruleData, errors));

const setExtractionDetails = data => dispatch => dispatch(actions.setExtractionDetails(data));

const readCurrentCalculation = getState => selectors.counter(selectors.readMe(getState()));

const countOfQuery = query => (dispatch, getState) => {
  const ruleData = query.expression.operands[0].operands || [];
  const lastResult = readCurrentCalculation(getState);

  const {
    audience: {
      audienceRules: {
        data: {
          dicts: { operatorTypes, expressionsDict },
        },
      },
    },
  } = getState();

  dispatch(actions.counting(lastResult));

  fetch
    .put(api.countByQuery, ruleSegmentBuilder(ruleData, operatorTypes, expressionsDict))
    .then(count => dispatch(actions.countSuccess(count)))
    .catch(error => {
      dispatch(actions.countFailure(error));
    });
};

const countOfSegment = rule => (dispatch, getState) => {
  try {
    const lastResult = readCurrentCalculation(getState);
    dispatch(actions.counting(lastResult));

    handleCount(JSON.parse(rule) || [], dispatch);
  } catch (error) {
    return true;
  }
};

const resetSegment = () => dispatch => dispatch(actions.resetSegment());

const addTag = tag => dispatch => dispatch(actions.addTag(tag));
const removeTag = tag => dispatch => dispatch(actions.removeTag(tag));

const removeSegment = (audience, field) => dispatch => dispatch(actions.removeSegment(audience, field));

export {
  getSegment,
  cloneSegment,
  newSegment,
  postSegment,
  uploadFile,
  setIncludedRules,
  countOfQuery,
  countOfSegment,
  resetSegment,
  addTag,
  removeTag,
  removeSegment,
  setExtractionDetails,
};

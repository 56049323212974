import { withStyles } from '@material-ui/core/styles';
import { isPlainObject } from 'ramda-adjunct';
import { compose, withProps, withHandlers, withStateHandlers } from 'recompose';

import { userName } from 'app/helpers/AppEnvHelpers/helpers';
import { VIRTUAL_ROOT } from 'app/helpers/FeatureMetadataHelpers/common';
import { segregateAllByDataQuality, toOrGroupPayloadOfValidRules, dataErrorStats } from 'app/helpers/FeatureMetadataHelpers/helpers';
import { displayUndo } from 'app/helpers/NotificationHelpers/helpers';
import { transformWhen, isNilOrEmpty, isNeitherNilNorEmpty, joinWithDot, fEmptyString, R } from 'app/helpers/RamdaHelpers/helpers';

import { OrRuleGroup } from './OrRuleGroup';
import { styles } from './styles';

const addStyles = withStyles(styles);

// option readers
const idOf = R.ifElse(isPlainObject, R.prop('value'), R.identity);

const updateStateWith = R.curry((updateStore, userId, data) => {
  // echoLog('updateStore & userid', updateStore, userId);
  // echoLog(data, 'Rules have been updated to ==>');

  const qualityResult = segregateAllByDataQuality(data);
  // echoLog(qualityResult, 'qualityResult ===>');
  const errors = dataErrorStats(qualityResult);
  // echoLog(errors, 'dataQualityStats =>');

  const defaultPayload = [{}];
  // const payload = isNilOrEmpty(data) || dataHasErrors(errors) ? [] : transformWhen(
  const payload = isNilOrEmpty(data)
    ? []
    : transformWhen(
        isNeitherNilNorEmpty,
        ([features, expression]) => [
          {
            expression,
            features,
            createdBy: userId,
            id: -1,
          },
        ],

        toOrGroupPayloadOfValidRules(qualityResult),
        defaultPayload,
      );

  // echoLog(payload, 'Payload for API ==>', JSON.stringify(payload));

  updateStore(payload, data, errors);

  return {
    data,
    errors,
  };
});

const addProps = withProps(({ updateState, optionsByPathKey }) => ({
  updateLocalState: updateStateWith(updateState, userName()),
  initialOptions: optionsByPathKey(VIRTUAL_ROOT),
  idOf,
  nameOf: R.either(R.prop('label'), idOf),
  descOf: R.either(R.prop('desc'), fEmptyString),
}));

const maxNumberOfItems = 4;
const maxNumberOfItemsV2 = 3;

const addHandlers = withHandlers({
  inclusivePathsOfFeature:
    ({ pathsOfFeature }) =>
    featureId =>
      [...pathsOfFeature?.(featureId), featureId],
  optionsBySelections:
    ({ initialOptions, optionsByPathKey }) =>
    selections =>
      isNilOrEmpty(selections)
        ? initialOptions
        : // todo: improve this check
        selections.length === (window.location.pathname === '/audience/new' ? maxNumberOfItemsV2 : maxNumberOfItems)
        ? undefined
        : optionsByPathKey(joinWithDot(R.map(idOf, selections))),
});

/*
const updateDecider = (previous, current) => {
  echoLog('Old & New ==>', previous, current, R.equals(previous, current));
  return true;
};
*/

const defaultDelay = 10;

const scrollToNodeWithDelay = (node, delay = defaultDelay) => {
  if (node) {
    setTimeout(() => node.scrollIntoView({ behavior: 'smooth' }), delay);
  }
};

const addStateHandlers = withStateHandlers(
  ({ data = [], errors = [] }) => ({
    data,
    errors,
    bottomNodeRef: null,
  }),

  {
    handleReceiveDomRef: () => node => ({
      bottomNodeRef: node,
    }),

    handleAdd:
      ({ data, bottomNodeRef }, { updateLocalState, initialRuleForGroup }) =>
      (item = initialRuleForGroup) => {
        scrollToNodeWithDelay(bottomNodeRef);

        return updateLocalState(R.append(item, data));
      },
    handleUpdate:
      ({ data }, { updateLocalState }) =>
      (update, index) =>
        updateLocalState(
          R.update(
            index,
            R.merge(data[index], {
              value: update,
            }),

            data,
          ),
        ),

    handleDelete:
      ({ data }, { updateLocalState, initialOptions }) =>
      (index, putBack) => {
        if (data.length === 1) return displayUndo('Cannot delete rule group');
        const undo = () => {
          putBack(data[index]);
        };

        displayUndo('Rule group deleted', undo);

        return updateLocalState(R.remove(index, 1, data));
      },
    handleClone:
      ({ data }) =>
      (index, clone) => {
        setTimeout(() => {
          clone(data[index]);
        }, 0);
      },
  },
);

const enhance = compose(addStyles, addProps, addHandlers, addStateHandlers);
export default enhance(OrRuleGroup);

import { PureComponent } from 'react';

import BaseToggle from './BaseToggle';

export { BaseToggle };

type Props = {
  active: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  onChange: (arg0: boolean) => unknown;
};

type State = {
  active: boolean;
};

export default class Toggle extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      active: !!props.active,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { active } = this.props;

    if (prevProps.active !== active) {
      this.setState({ active });
    }
  }

  render() {
    const { active } = this.state;
    const { className, disabled, id, onChange } = this.props;

    return <BaseToggle active={active} className={className} disabled={disabled} id={id} onChange={onChange} />;
  }
}

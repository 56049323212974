import * as types from './types';

const get = () => ({
  type: types.GET_SEGMENT,
});

const getSuccess = segment => ({
  type: types.GET_SEGMENT_SUCCESS,
  payload: { segment },
});

const getFail = error => ({
  type: types.GET_SEGMENT_FAIL,
  payload: { error },
});

const post = () => ({
  type: types.POST_SEGMENT,
});

const postSuccess = segmentId => ({
  type: types.POST_SEGMENT_SUCCESS,
  payload: { segmentId },
});

const postFail = error => ({
  type: types.POST_SEGMENT_FAIL,
  payload: { error },
});

const newSegment = () => ({
  type: types.NEW_SEGMENT,
});

const setName = name => ({
  type: types.SET_SEGMENT_FIELD,
  payload: { name },
});

const setDescription = description => ({
  type: types.SET_SEGMENT_FIELD,
  payload: { description },
});

const setUseCaseType = useCaseType => ({
  type: types.SET_SEGMENT_FIELD,
  payload: { useCaseType },
});

const setIncludedRules = (ruleExpression, ruleData, errors) => ({
  type: types.SET_SEGMENT_INCLUDED_RULES,
  payload: {
    ruleExpression,
    ruleData,
    errors,
  },
});

const setInitialRulesData = initialRules => ({
  type: types.SET_INITIAL_RULES_DATA,
  payload: { initialRules },
});

const setExtractionDetails = data => ({
  type: types.UPDATE_EXTRACTION_DETAILS,
  payload: data,
});

const setAudienceDetails = data => ({
  type: types.UPDATE_AUDIENCE_DETAILS,
  payload: data,
});

const addTag = tag => ({
  type: types.ADD_TAG,
  payload: { tag },
});

const removeTag = tag => ({
  type: types.REMOVE_TAG,
  payload: { tag },
});

const addCsv = () => ({
  type: types.ADD_CSV,
});

const addCsvSuccess = file => ({
  type: types.ADD_CSV_SUCCESS,
  payload: file,
});

const addCsvFail = error => ({
  type: types.ADD_CSV_FAIL,
  payload: { error },
});

const counting = state => ({
  type: types.CALC_COUNT_LOADING,
  payload: {
    ...state,
    isCalculating: true,
  },
});

const countSuccess = count => ({
  type: types.CALC_COUNT_SUCCESS,
  payload: {
    isCalculating: false,
    count,
    hasError: false,
  },
});

const countFailure = error => ({
  type: types.CALC_COUNT_FAILURE,
  payload: {
    isCalculating: false,
    count: -1,
    hasError: true,
    error,
  },
});

const resetSegment = () => ({
  type: types.RESET_SEGMENT,
});

const removeSegment = (segment, field) => ({
  type: types.REMOVE_SEGMENT,
  payload: {
    segment,
    field,
  },
});

export {
  get,
  getSuccess,
  getFail,
  post,
  postSuccess,
  postFail,
  newSegment,
  setName,
  setDescription,
  setIncludedRules,
  addTag,
  removeTag,
  addCsv,
  addCsvFail,
  addCsvSuccess,
  counting,
  countSuccess,
  countFailure,
  resetSegment,
  removeSegment,
  setExtractionDetails,
  setAudienceDetails,
  setUseCaseType,
  setInitialRulesData,
};

import { ReactNode } from 'react';

import { TableCell, TextField } from '@material-ui/core';
import { branch, withHandlers, renderComponent } from 'recompose';

const updateHandler = withHandlers({
  onChange:
    ({ column: { key }, onChange }) =>
    ({ target: { value } }) =>
      onChange({ [key]: value }),
});

const EditableColumn = updateHandler(({ column, clonedRow: row, onChange }) => (
  <TableCell>
    <TextField type={column.type || 'text'} value={row[column.key]} onChange={onChange} />
  </TableCell>
));

export interface StaticColumnProps {
  column: {
    key: string;
    type: string;
  };
  row: { [key: string]: ReactNode };
  valueFormatter?(column: StaticColumnProps['column'], row: StaticColumnProps['row']): ReactNode;
}

const StaticColumn = ({ column, row, valueFormatter }: StaticColumnProps) => (
  <TableCell>{valueFormatter ? valueFormatter(column, row) : row[column.key]}</TableCell>
);

export const ColumnSwitch = branch(
  ({ column: { editable }, editState }) => editable && editState,
  renderComponent(EditableColumn),
)(StaticColumn);

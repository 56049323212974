import { fetchPriorities, fetchExecutions } from 'app/api/masterplan';
import { fromExecutionDto } from 'app/api/masterplan/dto';
import { IMasterplanApiParams } from 'app/api/masterplan/types';
import { MANUAL, SYSTEM } from 'app/features/MasterPlan/constants';
import { DispatchFn } from 'app/types/state';

import * as actions from './actions';
import { mergeExecutions, priorityToExecution } from './utils';

export const getExecutions =
  ({ date, medium, trigger, useCaseType }: IMasterplanApiParams) =>
  async (dispatch: DispatchFn) => {
    dispatch(actions.getExecutions());

    const promisePriorities: Promise<any> = fetchPriorities({ date, medium, trigger, useCaseType })
      .then(result => ({ success: true, result }))
      .catch(error => ({ success: false, error }));

    const promiseExecutions = fetchExecutions({ date, medium, trigger, useCaseType })
      .then(result => ({ success: true, result }))
      .catch(error => ({ success: false, error }));

    const [resPriorities, resExecutions] = await Promise.all([promisePriorities, promiseExecutions]);

    const fromPriorities = resPriorities.success
      ? [
          ...resPriorities.result.manualPriorities.map(p => priorityToExecution(p, MANUAL)),
          ...resPriorities.result.systemPriorities.map(p => priorityToExecution(p, SYSTEM)),
        ]
      : [];

    const executions = resExecutions.success ? resExecutions.result.data.map(fromExecutionDto) : [];

    if (resPriorities.success || resExecutions.success) {
      dispatch(actions.getExecutionsSuccess({ executions: mergeExecutions(fromPriorities, executions) }));
    }

    if (!resPriorities.success || !resExecutions.success) {
      let msg;
      if (!resPriorities.success && !resExecutions.success) {
        msg = JSON.stringify({
          message: 'Remote Services Error',
          debugMessage: `Priorities Service Error: ${resPriorities.error.message}
          Executions Service Error: ${resExecutions.error.message}`,
        });
      } else if (!resPriorities.success) {
        msg = JSON.stringify({
          message: 'Priorities Service Error',
          debugMessage: resPriorities.error.message,
        });
      } else if (!resExecutions.success) {
        msg = JSON.stringify({
          message: 'Executions Service Error',
          debugMessage: resExecutions.error.message,
        });
      }
      dispatch(actions.getExecutionsError(Error(msg)));
    }
  };

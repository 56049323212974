import { ReactNode } from 'react';

import { connect } from 'react-redux';

import * as actions from 'app/ducks/campaigns/campaign/push2Creative/actions';

const mapStateToProps = ({
  campaigns: {
    campaign: {
      push2Creative,
      general: { expiryDate, expiryTime },
    },
  },
}) => {
  return {
    ...push2Creative,
    general: {
      expiryDate,
      expiryTime,
    },
  };
};

const push2Connect = connect(mapStateToProps, actions);

type ConnectedPush2Props = {
  children?: (props: any) => ReactNode;
};

export const ConnectedPush2 = push2Connect(({ children, ...props }: ConnectedPush2Props) => {
  return children ? <>{children(props)}</> : null;
});

import { IApiBannerCreative, IApiVariant } from 'app/api/bannerManagementV2/creatives/types';
import { BmsError } from 'app/api/bannerManagementV2/types';
import { IBannerCreativeConfig } from 'configs/apps/types';

export const toNumericIds = (values?: Array<string>): { ids: Array<number>; invalidValues: Array<string> } =>
  (values || []).reduce(
    ({ ids, invalidValues }, value) => {
      const id = Number(value);
      if (Number.isNaN(id)) {
        invalidValues.push(value);
      } else {
        ids.push(id);
      }
      return {
        ids,
        invalidValues,
      };
    },
    { ids: [], invalidValues: [] },
  );

/**
 * Parse a multiline string to give a list of numerid IDs.
 * Tokens are separated by either commas or new lines, or both.
 * Non-numeric values are discarded.
 */
export const parseNumericCsv = (content: string | null | undefined): { ids: Array<number>; invalidValues: Array<string> } =>
  toNumericIds(
    (content || '')
      .split(/[,\n]/)
      .map(str => str.trim())
      .filter(str => str.length),
  );

/**
 * Get an array of the unique values produced by applying the given getValueFn on the given creative and each of its variants.
 */
const getUniqueVariantValues = (
  creative: IApiBannerCreative,
  getValueFn: (variant: IApiBannerCreative | IApiVariant) => string | null | undefined,
): Array<string> => {
  const unique = new Set();

  const creativeValue = getValueFn(creative);
  creativeValue && unique.add(creativeValue);

  const { variants } = creative;

  (variants || []).forEach(v => {
    const str = getValueFn(v);
    str && unique.add(str);
  });

  // TODO: Do we need the Redux state as well?
  // Object.keys(variants || {}).forEach(key => {
  //   const str = getValueFn(variants[key]);
  //   str && unique.add(str);
  // });

  return Array.from(unique).sort();
};

export const getVariantTypes = (creative: IApiBannerCreative): Array<string> =>
  getUniqueVariantValues(creative, (obj: IApiBannerCreative | IApiVariant) => obj?.variantType?.name);

export const getWidgetTypes = (creative: IApiBannerCreative): Array<string> =>
  getUniqueVariantValues(creative, (obj: IApiBannerCreative | IApiVariant) => obj?.widgetType);

export const showWidgetType = (creativeConfig?: IBannerCreativeConfig, level?: string) => creativeConfig?.widgetType?.level === level;

export const toErrorString = (err: BmsError): string => {
  if (err.error) {
    const { app_error_code } = err.error;
    const { msg } = err.error;
    return `${app_error_code ? `[${app_error_code}] ` : ''}${msg}`;
  }

  return err.message;
};

import { css, CSSObject } from 'styled-components';

type Args = [CSSObject];

export default {
  smallScreen: (...args: Args) => css`
    @media (max-width: 576px) {
      ${css(...args)}
    }
  `,
  mediumScreen: (...args: Args) => css`
    @media (min-width: 576px) and (max-width: 899px) {
      ${css(...args)}
    }
  `,
  largeScreen: (...args: Args) => css`
    @media (min-width: 900px) {
      ${css(...args)}
    }
  `,
  smallerThanLargeScreen: (...args: Args) => css`
    @media (max-width: 899px) {
      ${css(...args)}
    }
  `,
  largerThanSmallScreen: (...args: Args) => css`
    @media (min-width: 576px) {
      ${css(...args)}
    }
  `,
};

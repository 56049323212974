import React, { Component } from 'react';

import { connect } from 'react-redux';

import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import styled from 'styled-components';

import { cashbackActions } from 'app/ducks/campaigns/campaign/cashbackCreative';
import { getRegularCashbackCreative, getJourneyCreative } from 'app/ducks/campaigns/campaign/cashbackCreative/selectors';
import { validateCalculation } from 'app/ducks/campaigns/campaign/cashbackCreative/validation';
import { getPromoEvents } from 'app/ducks/cashbackPromo/selectors';
import { Icon } from 'app/midgarComponents';
import StringSetPills from 'app/midgarComponents/TypedAttributes/InputTypes/StringSetPills';
import { sc } from 'app/styles';

import { RegularGlobalLimitContainer, JourneyGlobalLimitContainer } from './components/GlobalLimit';
import Fixed from './Fixed';
import Lottery from './Lottery';
import Proportional from './Proportional';
import { IPayoutStrategy } from './types';
import { calculationTypeLabelById, calculationTypes, percentTypeLabelById, percentType, cashbackTypes } from '../../constants';

type Props = IPayoutStrategy & {
  updateCalculationField: (arg0: Record<string, string>) => any;
  udpateCalculationType: (arg0: string) => any;
  updatePaymentMethods: (arg0: object) => any;
  cashbackCreative: any;
  hasLottery?: boolean;
  stageNum?: string;
  hasNoReward?: boolean;
};

class Calculation extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = { selectedPayments: '' };
  }

  getSelectedPayment() {
    const { cashbackCreative, stageNum } = this.props;
    const cashbackCreatives = typeof stageNum === 'undefined' || stageNum === '' ? cashbackCreative : cashbackCreative.stages[stageNum];
    const allowedPaymentMethods = cashbackCreatives?.calculation?.payoutStrategy?.allowedPaymentMethods;
    let selectedPayment = '';
    allowedPaymentMethods?.forEach(patmentMethod => {
      selectedPayment = !selectedPayment ? patmentMethod.name : `${selectedPayment},${patmentMethod.name}`;
    });
    return selectedPayment;
  }

  handlePaymentMethodValue = percentageValue => {
    const { updatePaymentMethods, cashbackCreative, stageNum } = this.props;
    const cashbackCreatives = typeof stageNum === 'undefined' || stageNum === '' ? cashbackCreative : cashbackCreative.stages[stageNum];
    const allowedPaymentMethods = cashbackCreatives?.calculation?.payoutStrategy?.allowedPaymentMethods;
    allowedPaymentMethods.find(method => method.name === percentageValue.name).percentage = percentageValue.value;
    updatePaymentMethods(allowedPaymentMethods);
  };

  renderCalculations = percentageType => {
    const { calculationType, cashbackCreative, stageNum } = this.props;
    const cashbackCreatives = typeof stageNum === 'undefined' || stageNum === '' ? cashbackCreative : cashbackCreative.stages[stageNum];
    const allowedPaymentMethods = cashbackCreatives?.calculation?.payoutStrategy?.allowedPaymentMethods;
    if (calculationType === calculationTypes.lottery) {
      return <Lottery {...this.props} />;
    }
    if (calculationType === calculationTypes.proportional) {
      return (
        <Proportional
          {...this.props}
          selectedPayments={this.getSelectedPayment()}
          percentageType={percentageType}
          allowedPaymentMethods={allowedPaymentMethods}
          handlePaymentMethodValue={this.handlePaymentMethodValue}
        />
      );
    }
    if (calculationType === calculationTypes.fixed) {
      return <Fixed {...this.props} />;
    }
    return null;
  };

  updateCalulationType = (calculationType: string) => {
    const { udpateCalculationType } = this.props;
    udpateCalculationType({ calculationType });
  };

  selectedPercentageType = (percentageType: string) => {
    const { updatePercentageType } = this.props;
    updatePercentageType({ percentageType });
  };

  changeValue = (selections, type) => {
    const { updatePaymentMethods, cashbackCreative, stageNum } = this.props;
    const allowedPaymentMethods = {};

    if (selections === '') {
      return updatePaymentMethods({ allowedPaymentMethods: [] });
    }
    const cashbackCreatives = typeof stageNum === 'undefined' || stageNum === '' ? cashbackCreative : cashbackCreative.stages[stageNum];
    cashbackCreatives?.calculation?.payoutStrategy?.allowedPaymentMethods?.forEach(item => {
      allowedPaymentMethods[item.name] = item;
    });
    const updatedAllowedPaymentMethods = [];
    const selectedPaymentMethods = selections.split(',');
    selectedPaymentMethods.forEach(methodName => {
      if (allowedPaymentMethods[methodName]) {
        updatedAllowedPaymentMethods.push(allowedPaymentMethods[methodName]);
      } else {
        if (type === 'PROPORTIONAL') {
          updatedAllowedPaymentMethods.push({
            name: methodName,
            percentage: 0,
          });
        } else {
          updatedAllowedPaymentMethods.push({
            name: methodName,
          });
        }
      }
    });
    updatePaymentMethods({ allowedPaymentMethods: updatedAllowedPaymentMethods });
  };
  isPayoutCalculationValid = () => validateCalculation(this.props).length === 0;

  getPaymentMethods = () => {
    const { promoEvents } = this.props;

    return promoEvents?.filter(event => event.name === 'Transaction')[0]?.promoEventFields?.filter(item => item.name === 'paymentMethod')[0]
      ?.possibleValues;
  };

  render() {
    const { calculationType, cashbackCreative, hasLottery = true, hasNoReward, stageNum = '' } = this.props;
    const cashbackCreatives = typeof stageNum === 'undefined' || stageNum === '' ? cashbackCreative : cashbackCreative.stages[stageNum];
    const percentageType = cashbackCreatives?.calculation?.payoutStrategy?.percentageType;
    const allowedPaymentMethods = cashbackCreatives?.calculation?.payoutStrategy?.allowedPaymentMethods;
    return (
      <Section data-qa="calculations">
        <Label>
          Calculations
          {this.isPayoutCalculationValid() ? (
            <ValidationIcon name="check-circle" size={22} color={sc.success} />
          ) : (
            <ValidationIcon name="warning" size={22} color={sc.warning} />
          )}
        </Label>
        <RadioGroup
          aria-label="calculation type"
          name="calculationType"
          value={calculationType}
          onChange={({ target: { value } }) => this.updateCalulationType(value)}
          style={{ display: 'inline-block' }}
        >
          <FormControlLabel control={<Radio />} value={calculationTypes.fixed} label={calculationTypeLabelById[calculationTypes.fixed]} />
          <FormControlLabel
            control={<Radio />}
            value={calculationTypes.proportional}
            label={calculationTypeLabelById[calculationTypes.proportional]}
          />

          {hasLottery ? (
            <FormControlLabel
              control={<Radio />}
              value={calculationTypes.lottery}
              label={calculationTypeLabelById[calculationTypes.lottery]}
            />
          ) : null}
          {hasNoReward ? (
            <FormControlLabel
              control={<Radio />}
              value={calculationTypes.noreward}
              label={calculationTypeLabelById[calculationTypes.noreward]}
            />
          ) : null}
        </RadioGroup>

        {stageNum === '' ? <RegularGlobalLimitContainer /> : <JourneyGlobalLimitContainer stageNum={stageNum} />}
        {calculationType === calculationTypes.lottery && (
          <>
            <StringSetPills
              name="payment-methods"
              value={this.getSelectedPayment()}
              label="Payment Methods"
              possibleValues={this.getPaymentMethods()} // TODO: Make StringsetPills able to handle string or array.
              handleChange={event => this.changeValue(event, 'LOTTERY')}
            />

            <Info data-qa="device-independent-info">
              <Icon name="info-outline" />
              cashback will be applicable for all the selected payment methods.
            </Info>
          </>
        )}

        {calculationType === calculationTypes.proportional && (
          <>
            <RadioGroup
              aria-label="payment type"
              name="paymentType"
              value={percentageType}
              onChange={({ target: { value } }) => this.selectedPercentageType(value)}
              style={{ display: 'inline-block' }}
            >
              <FormControlLabel control={<Radio />} value={percentType.global} label={percentTypeLabelById[percentType.global]} />
              <FormControlLabel control={<Radio />} value={percentType.perPayment} label={percentTypeLabelById[percentType.perPayment]} />
            </RadioGroup>
            {percentageType === 'PER_PAYMENT_METHOD' && (
              <StringSetPills
                name="payment-methods"
                value={this.getSelectedPayment()}
                label="Payment Methods"
                error={!allowedPaymentMethods || !allowedPaymentMethods.length}
                possibleValues={this.getPaymentMethods()} // TODO: Make StringsetPills able to handle string or array.
                handleChange={event => this.changeValue(event, 'PROPORTIONAL')}
                required
              />
            )}
          </>
        )}

        {this.renderCalculations(percentageType)}
      </Section>
    );
  }
}

export default connect(
  state => ({
    cashbackCreative:
      state.campaigns.campaign.cashbackCreative.type === cashbackTypes.regular
        ? getRegularCashbackCreative(state)
        : getJourneyCreative(state),
    promoEvents: getPromoEvents(state),
    userInfo: state.user,
  }),

  {
    updateCashbackCalculationField: cashbackActions.updateCashbackCalculationField,
    udpateCashbackCalculationType: cashbackActions.udpateCashbackCalculationType,
  },
)(Calculation);

const ValidationIcon = styled(Icon)`
  margin-top: -4px;
  margin-left: 0.5rem;
  font-weight: 900;
`;

const Label = styled.div`
  text-transform: uppercase;
  color: ${sc.grey};
  margin: 0 1rem 1rem 0;
  text-align: left;
  font-size: ${sc.fontSize};
`;

const Section = styled.section`
  display: ${props => (props.hide ? 'none' : 'block')};
  margin: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${sc.greyLight};
`;

const Info = styled.p`
  color: ${sc.grey};
  font-size: ${sc.fontSizeSmall};
  font-style: italic;
  padding: ${sc.gutterSmaller} 0;
`;

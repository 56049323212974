import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import { compose } from 'ramda';
import styled from 'styled-components';

import { DrawerError } from 'app/components';
import { creativeActions } from 'app/ducks';
import { hasFullWidthChar, toCamelCase } from 'app/ducks/helpers';
import { Separator } from 'app/features/BannerManagement/common/components';
import { sc } from 'app/styles';

import MetadataItem from './MetadataItem';

const asArray = (metadata: Record<string, string> | null | undefined) =>
  Object.entries(metadata || {})
    .map(([keyName, keyValue]) => ({ keyName, keyValue: String(keyValue) }))
    .sort((a, b) => {
      if (a.keyName > b.keyName) {
        return 1;
      }

      if (a.keyName < b.keyName) {
        return -1;
      }

      return 0;
    });

const asObject = (items: Array<{ keyName: string; keyValue: string }> | null | undefined): Record<string, string> =>
  (items || []).reduce((acc, { keyName, keyValue }) => (keyName && keyName.length > 0 ? { ...acc, [keyName]: keyValue } : acc), {});

type Props = {
  metadata?: Record<string, string>;
  readOnly?: boolean;
  title?: string;
  updateMetadata: (variantIndex: number, metadata: Record<string, string>) => void;
  variantKey: string;
  updateDuplicateMetadataFound: (duplicateMetadataFound: boolean) => void;
};

type State = {
  items: Array<{
    keyName: string;
    keyValue: string;
  }>;
};

class Metadata extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { metadata } = this.props;
    const items = asArray(metadata);
    this.state = {
      items: items.length > 0 ? items : [{ keyName: '', keyValue: '' }],
      duplicateKeys: [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    // This method ensures that the component is updated when a fetch has completed. It is only needed for opening existing variants.
    // This solution is not a good one, but it works at short notice. A better solution would be to introduce a loading flag.
    const { metadata, updateDuplicateMetadataFound } = this.props;
    const { items, duplicateKeys } = this.state;
    if (
      Object.keys(prevProps.metadata || {}).length === 0 &&
      Object.keys(metadata || {}).length > 0 &&
      Object.keys(asObject(items)).length === 0
    ) {
      // react/no-did-update-set-state set as warning because of this statement. This is a quick fix, and should only happen the first time a variant is retrieved
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        items: asArray(metadata),
      });
    }

    updateDuplicateMetadataFound(duplicateKeys.length);
  }

  isKeyNameDuplicate = (idx: number) => {
    const { items, duplicateKeys } = this.state;
    const isDuplicate = items.some(({ keyName }, i, arr) => i !== idx && toCamelCase(keyName) === toCamelCase(arr[idx].keyName));

    if (isDuplicate && !duplicateKeys.includes(idx)) {
      this.setState({
        duplicateKeys: [...duplicateKeys, idx],
      });
    } else if (!isDuplicate && duplicateKeys.includes(idx)) {
      this.setState({
        duplicateKeys: duplicateKeys.filter(key => key !== idx),
      });
    }

    return isDuplicate;
    // TODO: If duplicate key names are problematic, then the "Update" button should be disabled.
    // This would require a field for this purpose in Redux, so it's not worth the complication unless it turns out to be a user issue.
  };

  getKeyValueError = str => {
    return hasFullWidthChar(str) ? 'We detected a full width character, please double check if this is intentional' : '';
  };

  onAdd = () => {
    const { items } = this.state;
    this.setState({
      items: [...items, { keyName: '', keyValue: '' }],
    });
  };

  onDelete = (idx: number) => () => {
    const { updateMetadata, variantKey } = this.props;
    const { items, duplicateKeys } = this.state;
    if (duplicateKeys.includes(idx)) {
      this.setState({
        duplicateKeys: [],
      });
    }

    const newItems = items.slice();
    newItems.splice(idx, 1);
    this.setState({
      items: newItems,
    });

    updateMetadata(variantKey, asObject(newItems));
  };

  onChange = (idx: number) => (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { updateMetadata, variantKey } = this.props;
    const { items } = this.state;
    const newItems = items.slice();
    newItems[idx][ev.target.name] = ev.target.value;
    this.setState({
      items: newItems,
    });

    updateMetadata(variantKey, asObject(newItems));
  };

  handleInputBlur = (idx: number) => (ev: React.SyntheticEvent<HTMLInputElement>) => {
    const { updateMetadata, variantKey } = this.props;
    const { items } = this.state;
    const newItems = items.slice();
    newItems[idx][ev.target.name] = ev.target.value.trim();
    const similarSnakeCaseAndCamelCase = this.compareSnakeCaseAndCamelCase(newItems, idx);
    this.setState({
      items: newItems,
      similarSnakeCaseAndCamelCase,
    });

    updateMetadata(variantKey, asObject(newItems));
  };

  compareSnakeCaseAndCamelCase = (items: Record<string, any>, idx: number) => {
    const some = items.some(
      ({ keyName }, i, arr) =>
        i !== idx &&
        (toCamelCase(keyName) !== keyName || toCamelCase(arr[idx].keyName) !== arr[idx].keyName) &&
        toCamelCase(keyName) === toCamelCase(arr[idx].keyName),
    );

    return some;
  };

  render() {
    const { readOnly = false, title = 'Metadata' } = this.props;
    const { items = [], duplicateKeys } = this.state;

    return (
      <Container>
        <Separator>
          <span>{title}</span>
        </Separator>
        {duplicateKeys.length > 1 && (
          <DrawerError
            error={
              <>
                {duplicateKeys.map((key, i) => {
                  if (!items[key]?.keyName) {
                    return '';
                  }

                  if (i === duplicateKeys.length - 1) {
                    return ` and ${items[key].keyName}`;
                  } else if (i !== 0) {
                    return `, ${items[key].keyName}`;
                  }
                  return `${items[key].keyName}`;
                })}
                &nbsp;are duplicate key names and are not allowed.
              </>
            }
            errorDetails="snake_case and camelCase are treated same."
          />
        )}

        {items.map(({ keyName, keyValue }, idx) => (
          <MetadataItem
            key={idx} // eslint-disable-line react/no-array-index-key
            isLast={idx === items.length - 1}
            keyName={keyName}
            keyNameError={this.isKeyNameDuplicate(idx)}
            keyNameWarning={this.getKeyValueError(keyName)}
            keyValueWarning={this.getKeyValueError(keyValue)}
            keyValue={String(keyValue)}
            readOnly={readOnly}
            onAdd={this.onAdd}
            onDelete={this.onDelete(idx)}
            onChange={this.onChange(idx)}
            onBlur={this.handleInputBlur(idx)}
          />
        ))}
      </Container>
    );
  }
}

const mapDispatchToProps = {
  updateDuplicateMetadataFound: creativeActions.updateDuplicateMetadataFound,
};

export default compose(connect(null, mapDispatchToProps))(Metadata);

const Container = styled.div`
  position: relative;
  margin-top: ${sc.gutter};
`;

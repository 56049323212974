import React, { useState, useCallback } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { ButtonMenu, ButtonMenuProps } from './ButtonMenu';
import { styles } from './styles';

const ButtonMenuWithState: React.FC<ButtonMenuProps> = ({ selectedId, handleChangeSelected, classes, ...props }) => {
  const [menu, setMenu] = useState<HTMLElement | null>(null);

  const handleClick = useCallback(({ target }: React.MouseEvent<HTMLElement>) => {
    setMenu(target as HTMLElement);
  }, []);

  const handleClose = useCallback(() => {
    setMenu(null);
  }, []);

  const handleSelect = useCallback(
    (id: string) => () => {
      if (id !== selectedId && handleChangeSelected) {
        handleChangeSelected(id);
      }
      setMenu(null);
    },
    [selectedId, handleChangeSelected],
  );

  return (
    <ButtonMenu menu={menu} handleClick={handleClick} handleClose={handleClose} handleSelect={handleSelect} classes={classes} {...props} />
  );
};

export default withStyles(styles, { withTheme: true })(ButtonMenuWithState);

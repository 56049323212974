// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { triggerNames } from 'app/features/MasterPlan/constants';
import { ICampaignPriority } from 'app/types/ICampaignPriority';
import { triggerTypes } from 'app/utilities/constants';
import sleep from 'app/utilities/sleep';

import { IMasterplanApiParams, IPrioritiesResult } from './types';

const mockPriority = (i: number, trigger: string, rootName?: string) => ({
  id: i,
  name: `${rootName || 'Sample'} ${i}`,
  mediumId: 1,
  isRecurring: trigger === triggerTypes.oneTime || trigger === triggerTypes.recurring ? trigger === triggerTypes.recurring : undefined,
  // createdAt?: string, // NOT USED
  // dedupeStrategy?: string, // NOT USED
});

const mockScheduled = i => mockPriority(i, Math.random() > 0.2 ? triggerTypes.recurring : triggerTypes.oneTime);

const manualScheduledSeed = [
  {
    id: 30,
    name: 'FCampaign',
    createdAt: '2018-02-11T01:08:29',
    priority: 1,
    dedupeStrategy: null,
    isRecurring: true,
  },

  {
    id: 86,
    name: 'TEST campaign for regression',
    createdAt: '2018-03-20T16:45:05',
    priority: 2,
    dedupeStrategy: 'SOFT_LOCK_DEDUPE',
    isRecurring: false,
  },

  {
    id: 359,
    name: 'test-1337',
    createdAt: '2018-07-05T17:38:29',
    priority: 3,
    dedupeStrategy: 'SOFT_LOCK_DEDUPE',
    isRecurring: true,
  },

  {
    id: 360,
    name: 'test-1341',
    createdAt: '2018-07-05T17:42:02',
    priority: 4,
    dedupeStrategy: 'SOFT_LOCK_DEDUPE',
    isRecurring: true,
  },
];

const systemScheduledSeed = [
  {
    id: 293,
    name: '20+ Campaign',
    createdAt: '2018-05-08T18:06:48',
    dedupeStrategy: 'SOFT_LOCK_DEDUPE',
    isRecurring: false,
  },

  {
    id: 1081,
    name: 'foo-again',
    createdAt: '2019-02-01T21:42:25',
    dedupeStrategy: 'NO_DEDUPE',
    isRecurring: true,
  },

  {
    id: 1180,
    name: 'kamal-test-email',
    createdAt: '2019-03-19T17:06:00',
    dedupeStrategy: 'NO_DEDUPE',
    isRecurring: true,
  },

  {
    id: 29,
    name: 'Old People Test',
    createdAt: '2018-02-09T22:54:13',
    dedupeStrategy: null,
    isRecurring: true,
  },

  ...[...Array(600).keys()].map((_, i) => mockScheduled(2000 + i)),
];

const scheduledSeed = {
  manualPriorities: manualScheduledSeed,
  systemPriorities: systemScheduledSeed,
};

const cartAbandonSeed = {
  manualPriorities: [],
  systemPriorities: [mockPriority(4000, triggerTypes.cartAbandon, 'Sample Cart Abandon')],
};

const byTriggerAndMediumAndDate = {};

export const fetchPriorities = (params: IMasterplanApiParams): Promise<IPrioritiesResult> => {
  sleep();

  const { date, medium, trigger } = params;

  if (!byTriggerAndMediumAndDate[date]) {
    byTriggerAndMediumAndDate[date] = {};
  }

  if (!byTriggerAndMediumAndDate[date][medium]) {
    byTriggerAndMediumAndDate[date][medium] = {};
  }

  if (!byTriggerAndMediumAndDate[date][medium][trigger]) {
    if (trigger === triggerNames.cartAbandon) {
      byTriggerAndMediumAndDate[date][medium][trigger] = cartAbandonSeed;
    } else {
      byTriggerAndMediumAndDate[date][medium][trigger] = scheduledSeed;
    }
  }

  return {
    date,
    locked: false,
    medium,
    trigger,
    ...byTriggerAndMediumAndDate[date][medium][trigger],
  };
};

export const previewSystemPriorities = async (params: IMasterplanApiParams): Promise<IPrioritiesResult> => {
  const res = await fetchPriorities(params);
  const systemPriorities = res.systemPriorities
    .map(p => ({ ...p, priority: Math.random() }))
    .sort((a, b) => a.priority - b.priority)
    .map((p, i) => ({ ...p, priority: i + 1 }));
  return {
    ...res,
    systemPreview: true,
    systemPriorities,
  };
};

export const savePriorities = (params: IMasterplanApiParams, manualPriorities: Array<ICampaignPriority>) => {
  sleep();

  const { date, medium, trigger } = params;
  const { manualPriorities: oldManualPriorities, systemPriorities: oldSystemPriorities } = byTriggerAndMediumAndDate[date][medium][trigger];

  console.debug(
    'savePriorities: TODO',
    'date',
    date,
    'medium',
    medium,
    'trigger',
    trigger,
    'manualPriorities',
    manualPriorities,
    'oldManualPriorities',
    oldManualPriorities,
    'oldSystemPriorities',
    oldSystemPriorities,
  );
};

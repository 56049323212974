import React from 'react';

import styled from 'styled-components';

interface ToggleWithLabelProps {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  optionLabels: [string, string];
  disabled: boolean;
}

const ToggleWithLabel: React.FC<ToggleWithLabelProps> = ({ name, checked, onChange, optionLabels, disabled }) => {
  return (
    <ToggleSwitch className="toggle-switch">
      <input
        type="checkbox"
        id={name}
        name={name}
        className="toggle-switch-checkbox"
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        disabled={disabled}
      />

      {name ? (
        <label className="toggle-switch-label" tabIndex={disabled ? -1 : 1} htmlFor={name}>
          <span
            className={disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'}
            data-yes={optionLabels[1]}
            data-no={optionLabels[0]}
            tabIndex={-1}
          />

          <span className={disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'} tabIndex={-1} />
        </label>
      ) : null}
    </ToggleSwitch>
  );
};

const labelColour = '#bbb';
const disabledColour = '#ddd';
const white = '#fff';

const ToggleSwitch = styled.div`
  position: relative;
  margin-right: 10px;
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-align: left;
  margin-bottom: 4px;
  margin-left: 6px;

  .toggle-switch-checkbox {
    display: none;
  }

  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid ${labelColour};
    border-radius: 20px;
    margin: 0;

    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }

    > span:focus {
      outline: none;
    }
  }

  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 25px;
      padding: 0;
      line-height: 28px;
      font-size: 16px;
      color: #002e6e;
      font-weight: bold;
      box-sizing: border-box;
    }

    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: ${labelColour};
      color: #002e6e;
    }
  }

  .toggle-switch-disabled {
    background-color: ${disabledColour};
    cursor: not-allowed;

    &:before {
      background-color: ${disabledColour};
      cursor: not-allowed;
    }
  }

  .toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: ${labelColour};
    color: #002e6e;
    text-align: right;
  }

  .toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 5px;
    background: ${white};
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border: 0 solid ${labelColour};
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }

  .toggle-switch-checkbox:checked + .toggle-switch-label {
    .toggle-switch-inner {
      margin-left: 0;
    }

    .toggle-switch-switch {
      right: 0px;
    }
  }
`;

export default ToggleWithLabel;

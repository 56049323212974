import { CSSProperties } from 'react';

import TableBody from '@material-ui/core/TableBody';
import { map } from 'ramda';

import { EmptyTable } from './EmptyTable';
import { LoadingTable } from './LoadingTable';
import { Row } from './Row';

const renderTableRow = props => row => <Row key={row.bannerId || row.id} {...props} row={row} />;

interface BodyProps {
  rows?: unknown[];
  rowsLoading?: boolean; //eslint-disable-line;
  classes?: CSSProperties;
  columns?: object[];
}

export const Body = ({ rows = [], rowsLoading = false, ...props }: BodyProps) => (
  <TableBody>
    {rowsLoading && <LoadingTable {...props} />}
    {!rows.length && !rowsLoading ? <EmptyTable {...props} /> : null}
    {rows.length && !rowsLoading ? map(renderTableRow(props))(rows) : null}
  </TableBody>
);

import moment from 'moment';
import 'moment-timezone';

import { defaultDateFormat, defaultDateTimeFormat, tzUtc } from 'configs/dateTime';

/**
 * Parses the given date-time string, in UTC.
 * @param {string} dateTimeStr, as used in the moment library
 * @param {string} tz Default = UTC
 * @returns {moment}
 */
export const parseDateTimeUtc = (dateTimeStr: string, tz: string = tzUtc): moment.Moment => moment.tz(dateTimeStr, tz);

/**
 * Format the given `moment` in the given format, for the given timezone.
 * @param {moment} dateTime
 * @param {string} tz Default = UTC
 * @param {string} format Default is the HTML 5 date and time formats with a space separator, i.e. `yyyy-MM-dd HH:mm:ss`
 */
export const formatDateTime = (dateTime: moment.Moment, tz: string = tzUtc, format: string = defaultDateTimeFormat): string =>
  dateTime.clone().tz(tz).format(format);

/**
 * Similar to {@link #formatDateTime}, except that the input is a timestamp string, and the default format is `HH:mm`
 * @param {string*} ts, strign as used in the moment library
 * @param string*} timezone Default = UTC
 * @param {string} format Default is `HH:mm`
 */
export const formatTimestamp = (ts: string, timezone = tzUtc, format = 'HH:mm'): string =>
  formatDateTime(parseDateTimeUtc(ts), timezone, format);

/**
 * Format the given date and time strings in HTML5 format
 * @param {string*} dateStr
 * @param {string} timeStr
 * @param {string} timezone Default = UTC
 */
const asUtcDateTimeStr = (dateStr: string, timeStr: string, timezone: string = tzUtc): string => {
  if (!dateStr || !dateStr.length) return '';
  const m = moment.tz(`${dateStr}T${timeStr}`, timezone);
  if (m.isValid()) {
    return m.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
  }
  return '';
};

/**
 * Generate a comma-delimited string describing the data range, from midnight on the start date, to just before midnight of the day following the end date
 * @param {{ startDate: string, endDate: string, timezone: string }}
 * @returns {string}
 */
export const asQueryParamDateWindow = ({
  startDate,
  endDate,
  timezone = tzUtc,
}: {
  startDate: string;
  endDate: string;
  timezone: string;
}): string => {
  const startDateTime = asUtcDateTimeStr(startDate, '00:00:00', timezone);
  const endDateTime = asUtcDateTimeStr(endDate, '23:59:59', timezone);
  if ((startDateTime && startDateTime.length) || (endDateTime && endDateTime.length)) {
    return `${startDateTime},${endDateTime}`;
  }
  return '';
};

/**
 * Parse the given string representing a date-time range into the start and end dates.
 * If a time is specified after the delimiter `T`, it is discarded.
 * @param {string} str
 */
export const parseQueryParamDateWindow = (str?: string): { startDate?: string; endDate?: string } => {
  if (!str || !str.length) return {};

  const dates = str.split(',');
  const startDate = dates[0].split('T')[0];
  const endDate = dates.length > 1 ? dates[1].split('T')[0] : undefined;
  return {
    startDate: startDate.length ? startDate : undefined,
    endDate: endDate && endDate.length ? endDate : undefined,
  };
};

/**
 * Convert a given date-time string into a moment.
 * The difference between instantiating the moment directly is that if the input string cannot be parsed, `null` is returned.
 * @param {string*} str
 * @returns {?moment}
 */
export const momentFromDateStr = (str: string): moment.Moment | null | undefined => {
  if (str && str.length) {
    const m = moment(str);
    if (m.isValid()) return m;
  }
  return null;
};

/**
 * Format the given moment in the HTML5 date format.
 * The time component is suppressed.
 * @param {moment} m
 * @return {string}
 */
export const momentToDateStr = (m: moment.Moment): string => (m && m.isValid() ? m.format(defaultDateFormat) : '');

/**
 * Format the given moment in HTML5 date and time format, with a space delimiter
 * @param {moment} m
 * @returns {string}
 */
export const momentToDateTimeString = (m: moment.Moment): string => (m && m.isValid() ? m.format(defaultDateTimeFormat) : '');

/**
 * Standard formatting for displaying a date in the UI.
 * @param {string} date
 * @returns {string}
 */
export const formatDate = (date: string): string => (date === 'Not set' ? 'Not set' : moment(date).format("Do MMM'YY"));

/**
 * Standard formatting for displaying a date and time in the UI.
 * @param {string} date
 * @returns {string}
 */
export const formatTime = (date: string, format = 'hh : mm a'): string => (date === 'Not set' ? 'Not set' : moment(date).format(format));

import { IBannerCreative, ICreativeCategoryWeight, ILandingPageType, IVariant } from 'app/types/BannerManagement';
import { validateForm } from 'app/utilities/forms';
import { IBannerCreativeConfig } from 'configs/apps/types';

import { requiredCreativeFields, requiredVariantFields, CategoryFieldNames, VariantFieldNames } from './fields';

export const validateCreativeFields = (
  creative?: IBannerCreative | null | undefined,
  bannerConfig?: IBannerCreativeConfig,
): Array<string> => validateForm(creative || {}, requiredCreativeFields(bannerConfig)) || [];

export const validateVariantFields = (variant?: IVariant | null | undefined, bannerConfig?: IBannerCreativeConfig): Array<string> =>
  validateForm(variant || {}, requiredVariantFields(bannerConfig)) || [];

export const validateLandingPage = (variant?: IVariant, landingPageTypes?: Array<ILandingPageType>): Array<string> => {
  const { landingPageType } = variant || {};
  const matchingLandingPageType = (landingPageTypes || []).find(({ name }) => name === landingPageType);
  const { item_id_required: useItemId = false } = matchingLandingPageType || {};
  return validateForm(variant || {}, [useItemId ? VariantFieldNames.itemId : VariantFieldNames.url]);
};

export const isValidWeight = (rawWeight?: unknown) => {
  if (rawWeight === null || rawWeight === undefined || String(rawWeight).length === 0) {
    return false;
  }

  const weight = typeof rawWeight === 'number' ? rawWeight : Number(rawWeight);
  return !Number.isNaN(weight) && weight >= 0 && weight <= 100;
};

export const validateCategoryWeight = (categoryWeight?: ICreativeCategoryWeight | null) => {
  if (!categoryWeight || (!categoryWeight?.category?.id && (categoryWeight?.weight === undefined || categoryWeight?.weight === null))) {
    return [];
  }

  const invalidFields = [];
  if (!categoryWeight?.category?.id) {
    invalidFields.push(CategoryFieldNames.name);
  }
  if (!isValidWeight(categoryWeight?.weight)) {
    invalidFields.push(CategoryFieldNames.weight);
  }
  return invalidFields;
};

// TODO: Validate that the sum of all weights is 100
export const validateCategoryWeights = (
  categoryWeightByKey?: Record<string, ICreativeCategoryWeight> | null,
): Record<string, Array<string>> =>
  Object.keys(categoryWeightByKey || {}).reduce((acc, categoryKey) => {
    const categoryWeight = (categoryWeightByKey || {})[categoryKey];
    const invalidFields = validateCategoryWeight(categoryWeight);
    return {
      ...acc,
      [categoryKey]: invalidFields,
    };
  }, {});

export const validateVariants = (
  variants?: Record<string, IVariant> | null,
  landingPageTypes?: Array<ILandingPageType>,
  bannerConfig?: IBannerCreativeConfig,
): Record<string, Array<string>> =>
  Object.keys(variants || {}).reduce((acc, variantKey) => {
    const variant = (variants || {})[variantKey] || {};
    return {
      ...acc,
      [variantKey]: [...validateVariantFields(variant, bannerConfig), ...validateLandingPage(variant, landingPageTypes)],
    };
  }, {});

export const areMetadataValid = (variants?: Record<string, IVariant>): boolean => {
  let result = false;
  const variantKeys = Object.keys(variants || {});
  variantKeys.forEach((variantKey: string) => {
    const metadata = variants?.[variantKey]?.metadata || {};
    const metadataKeys = Object.keys(metadata) || [];
    metadataKeys.forEach(key => {
      const value = metadata[key] || '';
      if (key && value.length === 0) {
        result = true;
      }
      if (value && key.length === 0) {
        result = true;
      }
    });
  });
  return result;
};

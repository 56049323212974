import React from 'react';

import { TableRow, TableCell } from '@material-ui/core';

interface EmptyTableProps {
  columns?: object[];
}

export const EmptyTable = ({ columns }: EmptyTableProps) => (
  <TableRow>
    <TableCell style={{ textAlign: 'center' }} colSpan={columns?.length}>
      No records found
    </TableCell>
  </TableRow>
);

import React from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import Loading from '../Loading';

interface LoadingTableProps {
  columns?: object[];
}

export const LoadingTable = ({ columns }: LoadingTableProps) => (
  <TableRow>
    <TableCell colSpan={columns?.length}>
      <Loading />
    </TableCell>
  </TableRow>
);
